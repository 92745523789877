import AddRemoveImageButton from "@/components/AddRemoveImageButton";
import { Flex, FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import HtmlText from "@/components/HtmlText";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import { FlashcardControls } from "@/components/flashcards/FlashcardCard/FlashcardControls";
import { Flashcard, FlashcardSide } from "@knowt/syncing/graphql/schema";
import { useTextToSpeechContextSelector } from "@/contexts/TextToSpeechContext";
import { FlashcardsMastery } from "@knowt/syncing/hooks/study/statsUtils";
import br from "@/styles/breakpoints.module.css";
import ProgressPieChart from "../studyProgress/ProgressPieChart";
import ProgressNumBoxes from "../studyProgress/ProgressNumBoxes";
import { noop } from "lodash";
import { ArrowRight } from "lucide-react";
import { iconSizes } from "@/utils/iconProps";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";

const MasteryPerFlashcardCard = ({
    index,
    flashcard,
    readOnly,
    showEditFlashcard,
    toggleFlashcardStarByIds,
    flashcardMastery,
    openDetailsPopup,
    starredFlashcards,
}: {
    index: number;
    flashcard: Flashcard;
    readOnly: boolean;
    showEditFlashcard: (flashcard: Flashcard) => void;
    toggleFlashcardStarByIds: (id: string[]) => void;
    flashcardMastery: FlashcardsMastery["flashcardId"] | null | undefined;
    openDetailsPopup: () => void;
    starredFlashcards: string[];
}) => {
    const { term, definition, image, secondaryImage } = flashcard;

    const handlePlayAudio = useTextToSpeechContextSelector(state => state.handlePlayAudio);

    const renderButtons = () => (
        <FlashcardControls
            readOnly={readOnly}
            flashcard={flashcard as Flashcard}
            isStarred={starredFlashcards?.includes(flashcard.flashcardId) ?? false}
            showEditFlashcard={showEditFlashcard}
            toggleStarFlashcard={() => toggleFlashcardStarByIds([flashcard.flashcardId])}
            audioSide={FlashcardSide.BOTH}
            style={{ flexDirection: "column", alignItems: "center", justifyContent: "space-between", gap: "3rem" }}
        />
    );

    return (
        <Flex
            className={br.mdDownColumn}
            style={{
                padding: "4rem 2.4rem",
                borderRadius: "2rem",
                backgroundColor: themeColors.neutralWhite,
                fontSize: "2rem",
                width: "100%",
                gap: "4rem",
            }}>
            <span className="heading5">{index}</span>

            <Flex style={{ gap: "2rem", width: "70%" }} className={br.mdDownWidth100}>
                {/* TERM AND DEFINITION TEXT */}
                <Flex
                    style={{
                        width: "100%",
                        gap: spacing.MD,
                        justifyContent: "space-between",
                        fontSize: "1.8rem",
                        flexDirection: "column",
                        height: "100%",
                    }}>
                    {/* TERM */}
                    <Flex style={{ gap: spacing.SM, width: "35%" }}>
                        {secondaryImage && (
                            <div>
                                <AddRemoveImageButton
                                    editable={false}
                                    alt={term || "term image"}
                                    image={image}
                                    size="9rem"
                                />
                            </div>
                        )}
                        <HtmlText
                            onClick={async () => await handlePlayAudio(FlashcardSide.TERM, { flashcard })}
                            text={term}
                            style={{
                                width: "100%",
                                fontFamily: "var(--knowt-font-name)",
                                background: "transparent",
                            }}
                        />
                    </Flex>

                    {/* DEFINITION */}
                    <div style={{ backgroundColor: themeColors.neutral1, height: "1px" }} />
                    <Flex style={{ gap: spacing.SM, width: "65%" }}>
                        {secondaryImage && (
                            <div>
                                <AddRemoveImageButton
                                    editable={false}
                                    alt={term ?? "term image"}
                                    image={image}
                                    size="9rem"
                                    style={{ visibility: image ? "visible" : "hidden" }}
                                />
                            </div>
                        )}
                        <HtmlText
                            onClick={async () => await handlePlayAudio(FlashcardSide.DEFINITION, { flashcard })}
                            text={definition}
                            style={{
                                width: "100%",
                                fontFamily: "var(--knowt-font-name)",
                                background: "transparent",
                            }}
                        />
                    </Flex>
                </Flex>

                <div style={{ alignItems: "center", display: "flex" }}>{renderButtons()}</div>
            </Flex>

            {/* DIVIDER */}
            <div style={{ backgroundColor: themeColors.neutral1, width: "1px" }} />

            {/* PIE CHART AND PROGRESS */}
            <ProgressSection completionStats={flashcardMastery} openDetailsPopup={openDetailsPopup} />
        </Flex>
    );
};

const ProgressSection = ({ completionStats, openDetailsPopup }) => {
    return (
        <FlexColumn>
            <FlexRowAlignCenter style={{ gap: spacing.MD }}>
                <ProgressPieChart completionStats={completionStats} radius={45} thickness={45 * 0.4} />
                <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: spacing.MD }}>
                    <ProgressNumBoxes completionStats={completionStats} onProgressClick={noop} />
                </div>
            </FlexRowAlignCenter>
            <CircularRectTextButton
                onClick={openDetailsPopup}
                sx={{
                    color: "#416D97",
                    fontSize: "1.6rem",
                    gap: "0.5rem",
                    "&:hover": {},
                    "&:active": {},
                    width: "fit-content",
                    margin: `${spacing.SM} auto 0`,
                }}>
                view more details
                <ArrowRight size={iconSizes.MD_S} />
            </CircularRectTextButton>
        </FlexColumn>
    );
};

export default MasteryPerFlashcardCard;
