import { examNameToUrlComponent, getImageLink } from "@knowt/syncing/hooks/exams/utils";
import { SUBJECT_TO_CATEGORY } from "@knowt/syncing/hooks/subjects/constants";
import { FlashcardSet, Note } from "@knowt/syncing/graphql/schema";
import React, { useCallback, useMemo } from "react";
import styles from "@/components/NotePage/styles/main.module.css";
import Breadcrumb from "@/components/Breadcrumb";
import { subjectNameToUrlComponent } from "@knowt/syncing/hooks/subjects/utils";

type ContentTagBreadCrumbProps = {
    note?: Note;
    flashcardSet?: FlashcardSet | null;
};

const buildTagString = (...param: (string | null)[]) => {
    return param.includes(null) ? "/" : "/" + param.filter(Boolean).join("/");
};

const ContentTagBreadCrumb = ({ note, flashcardSet }: ContentTagBreadCrumbProps) => {
    const item = note || flashcardSet;

    const examType = item?.exam_v2?.split(" ")[0] ?? null;
    const subjectCategory = SUBJECT_TO_CATEGORY[item?.subject ?? ""] ?? null;

    const getCrumb = useCallback(
        (key: string, item?: Note | FlashcardSet | undefined) => {
            if (!item) return null;
            switch (key) {
                case "exam_v2":
                    return {
                        title: item.exam_v2,
                        path: buildTagString(
                            "exams",
                            examNameToUrlComponent(examType),
                            examNameToUrlComponent(item.exam_v2)
                        ),
                        image: getImageLink(item.exam_v2),
                    };
                case "examUnit":
                    return {
                        title: item.examUnit,
                        path: buildTagString(
                            "exams",
                            examNameToUrlComponent(examType),
                            examNameToUrlComponent(item.exam_v2),
                            item.examUnit
                        ),
                    };
                case "category": {
                    if (!subjectCategory) {
                        return null;
                    }
                    return {
                        title: subjectCategory,
                        path: buildTagString("subject", subjectNameToUrlComponent(subjectCategory)),
                        image: getImageLink(subjectCategory),
                    };
                }
                case "subject":
                    return {
                        title: item.subject,
                        path: buildTagString(
                            "subject",
                            subjectNameToUrlComponent(subjectCategory),
                            subjectNameToUrlComponent(item.subject)
                        ),
                        image: getImageLink(item.subject),
                    };
                case "topic":
                    // TODO: assert that item is not a Textbook type
                    return {
                        title: item.topic,
                        path: buildTagString(
                            "subject",
                            subjectNameToUrlComponent(subjectCategory),
                            subjectNameToUrlComponent(item.subject),
                            subjectNameToUrlComponent(item.topic)
                        ),
                    };
                default:
                    return null;
            }
        },
        [examType, subjectCategory]
    );

    const crumbs = useMemo(() => {
        const BREADCRUMBS_ORDER = ["category", "subject", "topic", "exam_v2", "examUnit"];

        const previousCrumbs = BREADCRUMBS_ORDER.map(key => {
            if (!item?.[key] && key !== "category") return null;

            if ((item?.exam_v2 && key === "subject") || key === "topic") return null;
            return getCrumb(key, item);
        }).filter(Boolean) as { title: string; path: string; image?: string }[];

        return previousCrumbs.map(({ title, path, image }) => ({
            title: <div className={styles.noteTitleBreadcrumbs}>{title}</div>,
            href: path,
            image,
        }));
    }, [item, getCrumb]);

    return <Breadcrumb titleSections={crumbs} />;
};

export default ContentTagBreadCrumb;
