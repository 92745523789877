import { FlashcardItem, FlashcardSetContext } from "../types";
import styles from "../flashcardSetOverview.module.css";
import { HorizontallyCenteredRepeatableLeaderboardAdSlot } from "@/features/Ads";
import PreviewFlashcardCard from "@/components/flashcards/FlashcardCard/PreviewFlashcardCard";

type Props = {
    item: FlashcardItem;
    context: FlashcardSetContext;
    index: number;
};

export const FlashcardItemRow = ({ item, index, context: { showEditFlashcard, readOnly } }: Props) => {
    return (
        <div className={styles.flashcardRow}>
            {item.type === "ad" ? (
                <HorizontallyCenteredRepeatableLeaderboardAdSlot
                    containerStyle={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    slotId={item.index}
                />
            ) : (
                <PreviewFlashcardCard
                    {...item}
                    index={index}
                    showEditFlashcard={showEditFlashcard}
                    readOnly={readOnly}
                />
            )}
        </div>
    );
};

FlashcardItemRow.displayName = "FlashcardItemRow";
