import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import OutlineTextButton from "@/components/CircularButton/styled/OutlineTextButton";
import { ConditionalLink } from "@/components/ConditionalLink";
import { Flex, FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import LazyLoaded from "@/components/LazyLoaded";
import Tags, { getTagHref } from "@/components/Tags";
import { ASSETS_URL } from "@/config/deployConstants";
import { useTaggingContextSelector } from "@/features/Tagging";
import { SectionPill } from "@/features/UserContentManagement";
import br from "@/styles/breakpoints.module.css";
import { now } from "@/utils/SyncUtils";
import { borderRadius } from "@/utils/borderRadius";
import { iconSizes } from "@/utils/iconProps";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import { ClassSection, FlashcardSet, ItemType } from "@knowt/syncing/graphql/schema";
import { useClass } from "@knowt/syncing/hooks/classes/useClass";
import { getVisibleSections } from "@knowt/syncing/hooks/classes/utils";
import { duplicateFlashcardSet } from "@knowt/syncing/hooks/flashcards/utils";
import { useCombinedTags } from "@knowt/syncing/hooks/tags/useTags";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { isDarkColor, renameKey } from "@knowt/syncing/utils/genericUtils";
import { Plus, PlusIcon } from "lucide-react";
import dynamic from "next/dynamic";
import Image from "next/image";
import { useRouter } from "next13-progressbar";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import styles from "../flashcardSetOverview.module.css";
import { FlashcardSetContext } from "../types";
import { SECTIONS_PILLS_TEXT_COLORS } from "@knowt/syncing/hooks/classes/constants";
import { useClassSharingPopupContextSelector } from "@/features/ShareSettings";
import { WALKTHROUGH_SET_DUPLICATE_BTN, WALKTHROUGH_SET_SECTION_PILL } from "../components/Walkthroughs";

const DuplicateAndAddToClassPopup = dynamic(
    () =>
        import(
            "@/features/UserContentManagement/ClassPage/TeacherView/components/popups/DuplicateAndAddToClassPopup/DuplicateAndAddToClassPopup"
        )
);

type Props = {
    flashcardSet: FlashcardSet;
    updateFlashcardSetMetaData: FlashcardSetContext["updateFlashcardSetMetaData"];
    readOnly?: boolean;
};

const FlashcardSetDescriptionPopup = dynamic(() => import("@/components/Popup/FlashcardSetDescriptionPopup"), {
    ssr: false,
});

const SectionPillBlock = ({ visibleSections }: { visibleSections: ClassSection[] | undefined }) => {
    const allSections = !visibleSections;
    const noSections = !visibleSections?.length;

    const getDefaultPillTextColor = (color: string) => {
        return isDarkColor(color) ? themeColors.pureWhite : themeColors.pureBlack;
    };

    return (
        <FlexRowAlignCenter
            style={{
                gap: spacing.XS,
                flexWrap: "wrap",
            }}>
            {allSections || noSections ? (
                <SectionPill
                    key={"0"}
                    sx={{
                        backgroundColor: themeColors.neutral3,
                        color: themeColors.neutralWhite,
                    }}>
                    {allSections ? "All Sections" : "No Sections"}
                </SectionPill>
            ) : (
                visibleSections.map((section, i) => (
                    <SectionPill
                        key={section.name + i}
                        sx={{
                            backgroundColor: section.color,
                            color: SECTIONS_PILLS_TEXT_COLORS[section.color] || getDefaultPillTextColor(section.color),
                        }}>
                        {section.name}
                    </SectionPill>
                ))
            )}
        </FlexRowAlignCenter>
    );
};

export const FlashcardSetDetails = ({ flashcardSet, updateFlashcardSetMetaData, readOnly }: Props) => {
    const router = useRouter();
    const { isTeacher, user } = useCurrentUser();
    const { tagsValueWithMetadata } = useCombinedTags(flashcardSet);

    const openTaggingPopup = useTaggingContextSelector(state => state.openTaggingPopup);
    const closeTaggingPopup = useTaggingContextSelector(state => state.closeTaggingPopup);

    const openClassSharingPopup = useClassSharingPopupContextSelector(state => state.openClassSharingPopup);

    const { course } = useClass({ classId: flashcardSet.classId });

    const visibleSections = getVisibleSections({
        sections: course?.sections,
        itemSections: flashcardSet.sections,
    });

    const onAddTagClick = () => {
        openTaggingPopup({
            flashcardSet,
            onSave: async newTags => {
                renameKey(newTags, "exam", "exam_v2");
                renameKey(newTags, "custom", "tags");
                await updateFlashcardSetMetaData(newTags);

                closeTaggingPopup();
            },
        });
    };

    const description = flashcardSet.description;

    const [isDescriptionPopupOpen, setIsDescriptionPopupOpen] = useState(false);
    const [nLines, setNLines] = useState(0);
    const [showMore, setShowMore] = useState(false);
    const [isDuplicateToClassPopupOpen, setIsDuplicateToClassPopupOpen] = useState(false);

    const descriptionRef = useRef<HTMLParagraphElement>(null);

    const isFlashcardSetOwner = flashcardSet.userId === user?.ID;

    useEffect(() => {
        if (!descriptionRef.current) return;
        setNLines(descriptionRef.current.getClientRects().length);
    }, [descriptionRef]);

    const renderTagsAndDescription = () => {
        if (readOnly && !tagsValueWithMetadata.length && !description) {
            return (
                <Flex
                    className={styles.setDetails}
                    style={{
                        flexDirection: "row",
                        alignItems: "center",
                        backgroundColor: themeColors.neutralWhite,
                        borderRadius: borderRadius.card,
                        padding: spacing.MD,
                        gap: spacing.LG,
                    }}>
                    <Image
                        src={`${ASSETS_URL}/images/kai-confused.svg`}
                        alt="encourage image"
                        width={80}
                        height={80}
                        style={{ objectFit: "contain" }}
                    />
                    <FlexColumn style={{ gap: spacing.XXS }}>
                        <h3 className="heading5">There's no tags or description</h3>
                        <p className="body2">Looks like no one added any tags here yet for you.</p>
                    </FlexColumn>
                </Flex>
            );
        }

        return (
            <FlexColumn
                style={{
                    backgroundColor: themeColors.neutralWhite,
                    borderRadius: borderRadius.card,
                    padding: spacing.MD,
                }}>
                <p className="bodyBold2" style={{ marginBottom: spacing.SM }}>
                    Tags & Description
                </p>
                <Flex
                    style={{
                        flexDirection: !tagsValueWithMetadata.length && !description ? "row" : "column",
                        gap: spacing.MD,
                    }}>
                    <Tags>
                        {({ renderTag }) => (
                            <FlexRowAlignCenter
                                style={{
                                    gap: "0.58rem",
                                    width: !tagsValueWithMetadata.length ? "auto" : "100%",
                                    flexWrap: "wrap",
                                }}>
                                {!!tagsValueWithMetadata.length &&
                                    tagsValueWithMetadata.map((tag, i: number) => {
                                        return (
                                            <ConditionalLink
                                                key={tag.value + i}
                                                href={getTagHref(tag)}
                                                condition={true}
                                                style={{ position: "relative", display: "flex", alignItems: "center" }}>
                                                {renderTag({
                                                    value: tag.value,
                                                    sx: {
                                                        paddingRight: "1.5rem",
                                                        fontSize: "1.4rem",
                                                        backgroundColor: themeColors.background,
                                                    },
                                                    key: tag.value + i,
                                                })}
                                            </ConditionalLink>
                                        );
                                    })}
                                {!readOnly && (
                                    <OutlineTextButton
                                        onClick={onAddTagClick}
                                        text={
                                            <>
                                                <p
                                                    style={{
                                                        fontSize: "1.4rem",
                                                        fontWeight: "400",
                                                        lineHeight: "2rem",
                                                    }}>
                                                    add tags
                                                </p>
                                                <Plus size={iconSizes.SM} />
                                            </>
                                        }
                                        sx={{
                                            color: themeColors.neutral3,
                                            padding: `${spacing.XS} ${spacing.SM}`,
                                            gap: spacing.XS,
                                            width: "max-content",
                                            height: "auto",
                                        }}
                                    />
                                )}
                            </FlexRowAlignCenter>
                        )}
                    </Tags>

                    {!description && !readOnly && (
                        <OutlineTextButton
                            onClick={() => setIsDescriptionPopupOpen(true)}
                            text={
                                <>
                                    <p style={{ fontSize: "1.4rem", fontWeight: "400", lineHeight: "2rem" }}>
                                        add description
                                    </p>
                                    <Plus size={iconSizes.SM} />
                                </>
                            }
                            sx={{
                                padding: `${spacing.XS} ${spacing.SM}`,
                                gap: spacing.XS,
                                width: "max-content",
                                height: "auto",
                            }}
                        />
                    )}
                    {!!description && (
                        <section style={{ marginBottom: "auto", width: "100%" }}>
                            {nLines === 0 && (
                                <div
                                    className="secondaryText1"
                                    style={{
                                        display: "inline",
                                        fontWeight: 400,
                                        overflow: "hidden",
                                        WebkitBoxOrient: "vertical",
                                        wordBreak: "break-all",
                                    }}
                                    ref={descriptionRef}>
                                    {description}
                                </div>
                            )}
                            {nLines !== 0 && (
                                <p
                                    className="secondaryText1"
                                    style={{
                                        fontWeight: 400,
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        display: "-webkit-box",
                                        WebkitBoxOrient: "vertical",
                                        wordBreak: "break-all",
                                        WebkitLineClamp: showMore ? undefined : 2,
                                        lineClamp: showMore ? undefined : 2,
                                    }}>
                                    {description}
                                </p>
                            )}
                            {nLines >= 3 && (
                                <div
                                    className="secondaryText1"
                                    style={{ cursor: "pointer", marginTop: spacing.XS }}
                                    onClick={() => setShowMore(!showMore)}>
                                    {showMore ? "Show less" : "Show more"}
                                </div>
                            )}
                        </section>
                    )}
                </Flex>
            </FlexColumn>
        );
    };

    const renderClassSharingDetails = () => {
        const isOutsideOfClass = readOnly || !flashcardSet?.classId;

        return (
            <Flex
                style={{
                    flexDirection: isOutsideOfClass ? "row" : "column",
                    justifyContent: isOutsideOfClass ? "space-between" : "unset",
                    alignItems: isOutsideOfClass ? "center" : "flex-start",
                    backgroundColor: themeColors.neutralWhite,
                    borderRadius: borderRadius.card,
                    padding: spacing.MD,
                }}
                className={WALKTHROUGH_SET_SECTION_PILL}>
                <p className="bodyBold2" style={{ marginBottom: isOutsideOfClass ? "unset" : spacing.SM }}>
                    Visible To
                </p>
                {!isOutsideOfClass ? (
                    <FlexColumn style={{ gap: spacing.XS_2, flexWrap: "wrap" }}>
                        <SectionPillBlock visibleSections={visibleSections} />
                        <CircularRectTextButton
                            onClick={() =>
                                openClassSharingPopup({
                                    fileId: flashcardSet.flashcardSetId,
                                    fileType: ItemType.FLASHCARDSET,
                                })
                            }
                            style={{
                                border: `1px solid ${themeColors.neutral1}`,
                                padding: "1.2rem 1.6rem",
                                width: "fit-content",
                            }}>
                            <span className="secondaryText2">Manage sharing</span>
                        </CircularRectTextButton>
                    </FlexColumn>
                ) : (
                    <CircularRectTextButton
                        className={WALKTHROUGH_SET_DUPLICATE_BTN}
                        onClick={() => setIsDuplicateToClassPopupOpen(true)}
                        style={{
                            borderRadius: "2rem",
                            border: `2px solid ${themeColors.primary}`,
                            backgroundColor: themeColors.primaryLight,
                            padding: "1.6rem 2.4rem",
                            gap: spacing.XS,
                        }}>
                        <PlusIcon size={iconSizes.SM} color={themeColors.primaryDark} strokeWidth={4} />
                        <p
                            className="secondaryTextBold1"
                            style={{
                                color: themeColors.primaryDark,
                            }}>
                            {isFlashcardSetOwner ? "Move to class" : "Duplicate and add flashcards to class"}
                        </p>
                    </CircularRectTextButton>
                )}
            </Flex>
        );
    };

    return (
        <>
            <FlexColumn
                className={br.smDownWidth100}
                style={{
                    width: "60%",
                    flex: 1,
                    gap: "2rem",
                }}>
                {isTeacher && renderClassSharingDetails()}
                {renderTagsAndDescription()}
            </FlexColumn>
            <LazyLoaded load={isDescriptionPopupOpen}>
                <FlashcardSetDescriptionPopup
                    isOpen={isDescriptionPopupOpen}
                    onClose={() => setIsDescriptionPopupOpen(false)}
                    onConfirm={async description => {
                        await updateFlashcardSetMetaData({ description });
                        toast.success("Flashcard set description added!");
                    }}
                />
            </LazyLoaded>
            <LazyLoaded load={isDuplicateToClassPopupOpen}>
                <DuplicateAndAddToClassPopup
                    isOpen={isDuplicateToClassPopupOpen}
                    onClose={() => setIsDuplicateToClassPopupOpen(false)}
                    itemTitles={[flashcardSet.title ?? "Untitled"]}
                    onSave={async ({ classId, sharedWithSections }) => {
                        if (!classId) return;
                        setIsDuplicateToClassPopupOpen(false);

                        if (!isFlashcardSetOwner) {
                            const newFlashcardSet = await toast.promise(
                                duplicateFlashcardSet(flashcardSet.flashcardSetId, user, {
                                    classId,
                                    sections: sharedWithSections,
                                }),
                                {
                                    loading: "Duplicating",
                                    success: "Flashcard set duplicated successfully!",
                                    error: "Failed to duplicate the Flashcard set",
                                }
                            );

                            if (newFlashcardSet) {
                                router.push(`/flashcards/${newFlashcardSet.flashcardSetId}`);
                            }
                        } else {
                            await updateFlashcardSetMetaData({
                                classId,
                                sections: sharedWithSections,
                                addedAt: now().toString(),
                                updated: now(),
                            }).then(() => {
                                toast.success("Flashcard set moved to class successfully!");
                                router.push(`/class/${classId}/dashboard?tab=dashboard`);
                            });
                        }
                    }}
                />
            </LazyLoaded>
        </>
    );
};
