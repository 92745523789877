import { UserDetails } from "@knowt/syncing/graphql/schema";
import React, { useState } from "react";
import { FlexColumn, FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import UserAvatar from "@/components/userCardComponents/UserAvatar";
import { themeColors } from "@/utils/themeColors";
import UserNameWithBadge from "@/components/UserNameWithBadge";
import { spacing } from "@/utils/spacing";
import { borderRadius } from "@/utils/borderRadius";
import LinkWrapper from "@/components/wrappers/Link/Link";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import Image from "next/image";
import { ASSETS_URL } from "@/config/deployConstants";
import Skeleton from "@mui/material/Skeleton";

const AnonymousUserContentOwnerCard = ({ className, style }: { className?: string; style?: React.CSSProperties }) => {
    return (
        <FlexRowAlignCenter
            className={className}
            style={{
                backgroundColor: themeColors.docsLight,
                color: themeColors.neutralBlack,
                borderRadius: borderRadius.card,
                padding: `${spacing.XL} ${spacing.MD}`,
                gap: "1.6rem",
                ...style,
            }}>
            <FlexColumnAlignJustifyCenter
                style={{
                    width: "fit-content",
                    backgroundColor: themeColors.neutralWhite,
                    padding: spacing.XS,
                    borderRadius: "50%",
                }}>
                <Image
                    src={`${ASSETS_URL}/images/anonymous-avatar.svg`}
                    alt="Anonymous user"
                    width={32}
                    height={32}
                    style={{ borderRadius: "50%" }}
                />
            </FlexColumnAlignJustifyCenter>
            <div style={{ width: "100%" }}>
                <span className="bodyBold2">Anonymous user</span>
            </div>
        </FlexRowAlignCenter>
    );
};

const LoadingOwnerCard = ({ className, style }: { className?: string; style?: React.CSSProperties }) => {
    return (
        <FlexColumn
            className={className}
            style={{
                justifyContent: "space-between",
                backgroundColor: themeColors.docsLight,
                color: themeColors.neutralBlack,
                borderRadius: borderRadius.card,
                padding: `${spacing.XL} ${spacing.MD}`,
                width: "fit-content",
                gap: "1.6rem",
                ...style,
            }}>
            <Skeleton animation="wave" variant="circular" width={"5.2rem"} height={"5.2rem"} />
            <div style={{ width: "100%" }}>
                <Skeleton animation="wave" width={"70%"} height={20} />
                <Skeleton animation="wave" width={"50%"} height={20} />
            </div>
            <div>
                <Skeleton animation="wave" height={40} width={80} />
            </div>
        </FlexColumn>
    );
};

const VerticalUserContentOwnerCard = ({
    owner,
    className,
    style,
}: {
    owner: Partial<UserDetails>;
    className?: string;
    style?: React.CSSProperties;
}) => {
    if (owner === undefined) {
        return <LoadingOwnerCard className={className} style={style} />;
    }

    if (owner === null) {
        return <AnonymousUserContentOwnerCard style={{ flex: 0.3 }} />;
    }

    return (
        <LinkWrapper
            href={`/u/${owner?.username}`}
            className={className}
            style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: themeColors.docsLight,
                color: themeColors.neutralBlack,
                borderRadius: borderRadius.card,
                padding: `${spacing.XL} ${spacing.MD}`,
                width: "fit-content",
                gap: "1.6rem",
                ...style,
            }}>
            <FlexColumn
                style={{
                    gap: "1rem",
                }}>
                <UserAvatar
                    src={owner?.pictureUrl}
                    radius={"5.2rem"}
                    alt={owner?.Name || owner?.username}
                    style={{ marginBottom: spacing.XS }}
                />
                <div style={{ marginRight: spacing.XS_2 }}>
                    <UserNameWithBadge user={owner} type="Name" className="ellipsisText bodyBold2" />
                    <div
                        className="secondaryTextBold2"
                        style={{ marginBottom: spacing.XXS }}>{`@${owner?.username}`}</div>
                </div>
            </FlexColumn>

            <CircularRectTextButton
                className="secondaryTextBold1"
                style={{
                    color: themeColors.neutralWhite,
                    backgroundColor: themeColors.primary4,
                    padding: "1rem 1.2rem",
                    width: "fit-content",
                    height: "fit-content",
                }}>
                view profile
            </CircularRectTextButton>
        </LinkWrapper>
    );
};

const UserContentOwnerCard = ({
    owner,
    className,
    style,
    variant = "horizontal",
}: {
    owner: Partial<UserDetails>;
    className?: string;
    style?: React.CSSProperties;
    variant?: "horizontal" | "vertical";
}) => {
    const [isHover, setIsHover] = useState(false);

    if (variant === "vertical")
        return <VerticalUserContentOwnerCard owner={owner} className={className} style={style} />;

    if (owner === undefined) {
        return <LoadingOwnerCard className={className} style={style} />;
    }

    if (owner === null) {
        return <AnonymousUserContentOwnerCard style={{ padding: spacing.MD }} />;
    }

    return (
        <LinkWrapper
            href={`/u/${owner?.username}`}
            className={className}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: themeColors.docsLight,
                color: themeColors.neutralBlack,
                borderRadius: borderRadius.card,
                padding: `${spacing.XL} ${spacing.MD}`,
                ...style,
            }}>
            <FlexRowAlignCenter>
                <UserAvatar
                    src={owner?.pictureUrl}
                    radius={"5.2rem"}
                    alt={owner?.Name || owner?.username}
                    style={{ marginRight: "1rem" }}
                />
                <div style={{ marginRight: spacing.XS_2 }}>
                    <UserNameWithBadge
                        user={owner}
                        type="Name"
                        className="ellipsisText"
                        style={{ fontSize: "1.5rem", fontWeight: "600" }}
                    />
                    <div className="secondaryText2" style={{ marginBottom: spacing.XXS }}>{`@${owner?.username}`}</div>
                </div>
            </FlexRowAlignCenter>
            <div
                className="secondaryText2"
                style={{
                    backgroundColor: isHover ? themeColors.icon1 : "transparent",
                    transition: "all 0.2s ease-in-out",
                    padding: `${spacing.XS_2} ${spacing.SM}`,
                    border: `1px solid ${themeColors.icon1}`,
                    borderRadius: borderRadius.shortInput,
                    color: isHover ? themeColors.neutralWhite : themeColors.neutralBlack,
                }}>
                view
            </div>
        </LinkWrapper>
    );
};

export default UserContentOwnerCard;
