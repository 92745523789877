"use state";

import CircularRectTextButton, {
    CircularRectTextButtonProps,
} from "@/components/CircularButton/styled/CircularRectTextButton";

const SectionPill = ({ children, sx, onClick, ...btnProps }: CircularRectTextButtonProps) => {
    return (
        <CircularRectTextButton
            onClick={onClick}
            sx={{
                padding: "0 1.6rem",
                height: "4.4rem",
                fontSize: "1.4rem",
                fontWeight: "400",
                ...sx,
            }}
            {...btnProps}>
            {children}
        </CircularRectTextButton>
    );
};

export default SectionPill;
