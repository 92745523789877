import StarRating from "@/components/StarRating";
import CircularButton from "@/components/CircularButton";
import { createRating, updateRating } from "@knowt/syncing/hooks/rating/utils";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { ItemType, MediaType, Rating } from "@knowt/syncing/graphql/schema";
import noop from "@knowt/syncing/utils/noop";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import LoadingAnimation from "../LoadingAnimation";
import SlideMenu from "../SlideMenu";
import { themeColors } from "@/utils/themeColors";
import Mixpanel from "@/utils/analytics/Mixpanel";
import styles from "@/styles/ratingPopup.module.css";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";
import RoundInput from "@/components/styled/input/RoundInput";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import CloseButton from "../CircularButton/styled/CloseButton";
import { spacing } from "@/utils/spacing";
import Dialog from "../Dialog";
import { KeyedMutator } from "swr";
import { useMedia } from "@knowt/syncing/hooks/media/useMedia";
import { useNote } from "@knowt/syncing/hooks/notes/useNotes";
import { useFlashcardSet } from "@knowt/syncing/hooks/flashcards/useFlashcards";

const badFeedbackList = ["Not enough detail", "Incomplete or irrelevant", "Poorly formatted", "Other"];
const goodFeedbackList = ["Comprehensive", "What I was looking for", "Formatted well", "Other"];
const allFeedbackList = [...badFeedbackList.slice(0, 3), ...goodFeedbackList.slice(0, 3)];

const mixpanelTypes: { [key: string]: string } = {
    [ItemType.NOTE]: "Note",
    [ItemType.FLASHCARDSET]: "Flashcard Set",
    [ItemType.MEDIA]: "Media",
};

const FeedbackItem = ({
    onClick,
    isSelected,
    feedbackText,
}: {
    onClick: () => void;
    isSelected: boolean;
    feedbackText: string;
}) => {
    const { smDown } = useBreakPoints();

    if (smDown)
        return (
            <div
                style={{
                    padding: "1.6rem",
                    border: `2px solid ${isSelected ? themeColors.neutralBlack : themeColors.neutral1}`,
                    borderRadius: "1rem",
                }}
                onClick={onClick}>
                <div style={{ color: themeColors.neutralBlack, fontWeight: isSelected ? "600" : "400" }}>
                    {feedbackText}
                </div>
            </div>
        );

    return (
        <div className={styles.feedbackItem} onClick={onClick}>
            <CircularButton
                sx={{
                    border: "2px solid #eeeeee",
                    backgroundColor: isSelected ? themeColors.inverted : "transparent",
                    paddingRight: "0.3rem",
                }}
                radius="3rem"
            />
            <div>{feedbackText}</div>
        </div>
    );
};

type RatingPopupProps = {
    documentId?: string;
    documentOwnerId: string;
    documentType: ItemType;
    userRating: Rating | null;
    isOpen: boolean;
    onClose: () => void;
};

const RatingPopup = ({ documentId, documentOwnerId, documentType, userRating, isOpen, onClose }: RatingPopupProps) => {
    const [ratingValue, setRatingValue] = useState(0);
    const [selectedFeedback, setSelectedFeedback] = useState(null);
    const [customFeedbackMessage, setCustomFeedbackMessage] = useState("");
    const [feedbackList, setFeedbackList] = useState(badFeedbackList);
    const { smDown } = useBreakPoints();

    const isNote = documentType === ItemType.NOTE;
    const isFlashcardSet = documentType === ItemType.FLASHCARDSET;
    const isMedia = documentType === ItemType.MEDIA;

    const { note, mutate: mutateNote } = useNote({ noteId: documentId, isEnabled: isNote });

    const { flashcardSet, mutate: mutateFlashcard } = useFlashcardSet({
        flashcardSetId: documentId,
        isEnabled: isFlashcardSet,
    });

    const { media, mutate: mutateMedia } = useMedia({
        mediaId: documentId,
        isEnabled: isMedia,
    });

    const item = isNote ? note : isFlashcardSet ? flashcardSet : media;
    const mutateItem: KeyedMutator<any> = isNote ? mutateNote : isFlashcardSet ? mutateFlashcard : mutateMedia;

    const isCustomFeedback = selectedFeedback === "Other";
    const [isLoading, setIsLoading] = useState(false);

    const { userId: authenticatedUserId } = useCurrentUser();

    useEffect(() => {
        setFeedbackList(ratingValue >= 4 ? goodFeedbackList : badFeedbackList);
        setSelectedFeedback(null);
    }, [ratingValue]);

    useEffect(() => {
        if (!userRating) return;

        const { rating, message } = userRating;
        setRatingValue(rating);
        setSelectedFeedback(message);

        const isCustomMessage = allFeedbackList.every(feedback => feedback !== message);

        if (isCustomMessage) {
            setCustomFeedbackMessage(message);
            setSelectedFeedback("Other");
        }
    }, [userRating, isOpen]);

    const closeAndReset = () => {
        onClose();
        setRatingValue(0);
        setSelectedFeedback(null);
        setCustomFeedbackMessage("");
    };

    const specificType = (() => {
        if (isNote) return "note";
        if (isFlashcardSet) return "flashcard";
        if (!media) return "media";
        if (media.type === MediaType.AUDIO) return "audio";
        if (media.type === MediaType.PDF) return "pdf";
        if (media.type === MediaType.VIDEO) return "video";
    })();

    const renderRatingContent = () => {
        return (
            <>
                <div
                    style={{
                        position: "absolute",
                        top: 25,
                        right: 25,
                    }}>
                    <CloseButton onClick={onClose} />
                </div>
                <div className={styles.bodyContainer} style={{ marginTop: "5rem" }}>
                    <div className="heading4" style={{ color: themeColors.neutralBlack }}>
                        {userRating ? "Update your rating!" : "Leave a rating!"}
                    </div>
                    <StarRating
                        precision={1}
                        value={ratingValue}
                        max={5}
                        onChange={(_event, newValue) => setRatingValue(newValue)}
                        sx={{ fontSize: "5rem", margin: "3.5rem 0 2rem" }}
                    />
                    {(smDown || ratingValue > 0) && (
                        <>
                            <div style={{ color: themeColors.neutralBlack }} className={styles.feedbackContainer}>
                                <div className={styles.feedbackTitle}>
                                    {smDown
                                        ? "Tell us what you liked!"
                                        : ratingValue >= 4
                                        ? `Tell us what you liked about this ${specificType}!`
                                        : `Tell us what could be better about this ${specificType}`}
                                </div>
                                <div className={styles.feedbackItemsContainer}>
                                    {feedbackList.map(feedback => (
                                        <FeedbackItem
                                            key={feedback}
                                            feedbackText={feedback}
                                            isSelected={feedback === selectedFeedback}
                                            onClick={() => {
                                                setCustomFeedbackMessage("");
                                                setSelectedFeedback(feedback);
                                            }}
                                        />
                                    ))}
                                </div>
                            </div>
                            {selectedFeedback === "Other" && (
                                <div style={{ width: smDown ? "90%" : "78%", marginBottom: "7.5rem" }}>
                                    {smDown ? (
                                        <textarea
                                            className="knowtFontBold"
                                            value={customFeedbackMessage}
                                            placeholder="Leave your feedback here!"
                                            onChange={event => setCustomFeedbackMessage(event.target.value)}
                                            style={{
                                                padding: "2rem",
                                                fontSize: "1.6rem",
                                                width: "90%",
                                                height: "9rem",
                                                borderRadius: "1.6rem",
                                                color: themeColors.neutralBlack,
                                                backgroundColor: "transparent",
                                                border: `2px solid ${themeColors.neutralBlack}`,
                                            }}
                                        />
                                    ) : (
                                        <RoundInput
                                            className="knowtFontBold"
                                            value={customFeedbackMessage}
                                            onChange={event => setCustomFeedbackMessage(event.target.value)}
                                            placeholder="Write your feedback..."
                                            autoFocus
                                            style={{
                                                fontSize: "1.6rem",
                                                width: "100%",
                                                height: "5.2rem",
                                                borderRadius: "1.6rem",
                                            }}
                                        />
                                    )}
                                </div>
                            )}
                            <CircularRectTextButton
                                onClick={async () => {
                                    const feedbackMessage = isCustomFeedback ? customFeedbackMessage : selectedFeedback;

                                    setIsLoading(true);

                                    const isNew = !userRating;

                                    const submitRating = isNew ? createRating : updateRating;
                                    await submitRating({
                                        noteId: documentId,
                                        userId: authenticatedUserId,
                                        noteOwnerId: documentOwnerId,
                                        message: feedbackMessage,
                                        rating: ratingValue,
                                    });

                                    mutateItem(
                                        () => {
                                            const oldRating = item?.rating || 0;
                                            const oldCount = item?.ratingCount || 0;

                                            if (isNew) {
                                                return {
                                                    ...item,
                                                    rating: (oldRating * oldCount + ratingValue) / (oldCount + 1),
                                                    count: oldCount + 1,
                                                };
                                            } else {
                                                return {
                                                    ...item,
                                                    rating:
                                                        (oldRating * oldCount +
                                                            ratingValue -
                                                            (userRating?.rating || 0)) /
                                                        oldCount,
                                                };
                                            }
                                        },
                                        {
                                            revalidate: false,
                                        }
                                    );

                                    Mixpanel.track(`${mixpanelTypes[documentType]} - Rated`, {
                                        id: documentId,
                                        type: documentType,
                                        rating: ratingValue,
                                        message: feedbackMessage,
                                    });

                                    setIsLoading(false);
                                    toast.success("Thanks! Your rating has been shared with the owner!");
                                    closeAndReset();
                                }}
                                className="bodyBold1"
                                sx={{
                                    backgroundColor: themeColors.primary,
                                    color: themeColors.pureWhite,
                                    width: "fit-content",
                                    padding: `${spacing.XS} ${spacing.MD}`,
                                }}>
                                {isLoading ? <LoadingAnimation style={{ color: "#50D2C2" }} size={28} /> : "submit"}
                            </CircularRectTextButton>
                        </>
                    )}
                </div>
            </>
        );
    };

    const desktopContent = (
        <Dialog
            TransitionProps={{ unmountOnExit: true }}
            open={isOpen}
            onClose={closeAndReset}
            maxWidth={false}
            PaperProps={{
                style: {
                    backgroundColor: themeColors.background,
                    color: themeColors.neutralBlack,
                    borderRadius: "4rem",
                    width: "70rem",
                    padding: "5rem 1.6rem",
                    marginBottom: "29rem",
                },
            }}>
            {renderRatingContent()}
        </Dialog>
    );

    const mobileContent = (
        <SlideMenu isOpen={isOpen} onClose={closeAndReset} onClickAway={noop}>
            {renderRatingContent()}
        </SlideMenu>
    );

    return smDown ? mobileContent : desktopContent;
};

export default RatingPopup;
