import { useMemo } from "react";
import { useSchoolName } from "@/hooks/schools/useSchool";
import { FlashcardSet, Media, Note } from "@knowt/syncing/graphql/schema";
import { useCategoryBySubject, useSubjectByTopic } from "@/hooks/subjects/useSubjects";

export const useCustomTags = (item?: FlashcardSet | Note | Media) => {
    const customTags = useMemo(() => item?.tags || [], [item?.tags]);
    return { customTags };
};

const getTopicTag = (topic?: string | null) => {
    if (!topic || topic.toLowerCase() === "other") {
        return null;
    }

    // for legacy reasons, some topics might be a "stringified array" instead of a normal string,
    // so we should ignore those topics and not show them as a tag.
    // This check is expected to be less valuable with time.
    if (/\["/i.test(topic)) {
        return null;
    }

    return topic;
};

export const useCombinedTags = (item?: FlashcardSet | Note | Media) => {
    const { customTags } = useCustomTags(item);
    const { schoolId, grade, subject, topic, examUnit, examSection, exam_v2 } = item || {};

    const schoolName = useSchoolName(schoolId);
    const category = useCategoryBySubject(subject);
    const topicMetadata = useSubjectByTopic(topic);

    const isLoading =
        (schoolId && typeof schoolName === "undefined") ||
        (subject && typeof category === "undefined") ||
        (topic && typeof topicMetadata === "undefined");

    const tagsValueWithMetadata = (() => {
        const subjectTag = { type: "subject", value: subject, category };

        const topicTag = {
            type: "topic",
            value: getTopicTag(topic),
            subject: topicMetadata?.subject,
            category: topicMetadata?.category,
        };

        const schoolTag = { type: "school", value: schoolName, id: schoolId };
        const examTag = { type: "exam", value: exam_v2 };
        const examUnitTag = { type: "examUnit", value: examUnit, exam: exam_v2 };
        const examSectionTag = { type: "examSection", value: examSection };
        const gradeTag = { type: "grade", value: grade };
        const customTagList = customTags?.map?.(tag => ({ type: "custom", value: tag })) || [];

        return [
            subjectTag,
            topicTag,
            schoolTag,
            examTag,
            examUnitTag,
            examSectionTag,
            ...customTagList,
            gradeTag,
        ].filter(tag => !!tag.value) as TagValueWithMetadata[];
    })();

    const combinedTags = useMemo(() => {
        return tagsValueWithMetadata.map(tag => tag.value);
    }, [tagsValueWithMetadata]);

    return { combinedTags, tagsValueWithMetadata, isLoading };
};

export type TagValueWithMetadata = {
    type: "subject" | "topic" | "school" | "exam" | "custom" | "grade" | "examUnit";
    value: string;
    category?: string;
    subject?: string;
    exam?: string;
    id?: string;
};
