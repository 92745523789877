import { VerifiedSchool } from "@knowt/syncing/graphql/schema";
import { runESQueryFull } from "../../fetchFunctions/elasticsearch";

export const fetchSchools = async (queryPhrase: string[], queryFields = ["schoolId"]) => {
    return (
        await runESQueryFull<VerifiedSchool>({
            queryFields,
            queryPhrase,
            returnFields: ["name", "schoolId", "country", "state", "address.city", "address.zip"],
            searchIndex: ["VERIFIED_SCHOOL"],
            exactMatch: true,
        })
    )?.items;
};

export const fetchSchoolsById = async (schoolIds: string[]) => {
    return await fetchSchools(schoolIds, ["schoolId"]);
};

export const getSchoolIdsByName = async (schoolNames: string[]) => {
    const school = await fetchSchools(schoolNames, ["name"]);
    return school?.[0]?.schoolId;
};
