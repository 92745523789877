"use client";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { Flex, FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import { borderRadius } from "@/utils/borderRadius";
import { themeColors } from "@/utils/themeColors";
import { ArrowRight } from "lucide-react";
import { iconSizes, strokeWidth } from "@/utils/iconProps";
import { useFlashcardSetCompletion } from "@knowt/syncing/hooks/study/useStudySessionStats";
import br from "@/styles/breakpoints.module.css";
import ProgressPieChart from "./studyProgress/ProgressPieChart";
import { spacing } from "@/utils/spacing";
import { FlashcardSet, ItemType, StudySessionProgressEnum } from "@knowt/syncing/graphql/schema";
import LazyLoaded from "@/components/LazyLoaded";
import { useState } from "react";
import dynamic from "next/dynamic";
import ProgressNumBoxes from "./studyProgress/ProgressNumBoxes";
import { useClassManagementContextSelector } from "../hooks/useClassManagementContext";
import { isDarkColor } from "@knowt/syncing/utils/genericUtils";

const AddClassFilePopup = dynamic(() =>
    import("@/features/UserContentManagement").then(({ AddClassFilePopup }) => AddClassFilePopup)
);

const MoveFileToClassPopup = dynamic(() =>
    import("@/features/UserContentManagement").then(({ MoveFileToClassPopup }) => MoveFileToClassPopup)
);

const EmptyState = ({
    containerStyle,
    className,
    isOnlySetDraft,
}: {
    containerStyle?: React.CSSProperties;
    className?: string;
    isOnlySetDraft?: boolean;
}) => {
    const course = useClassManagementContextSelector(state => state.course);

    const [isAddClassFilePopupOpen, setIsAddClassFilePopupOpen] = useState(false);
    const [isMoveFileToClassPopupOpen, setIsMoveFileToClassPopupOpen] = useState(false);

    return (
        <>
            <FlexColumn
                className={className}
                style={{
                    flex: 1,
                    backgroundColor: themeColors.neutralWhite,
                    borderRadius: borderRadius.card,
                    padding: "1.6rem",
                    ...containerStyle,
                }}>
                <FlexRowAlignCenter style={{ justifyContent: "space-between", marginBottom: "1rem" }}>
                    <p className="bodyBold2">Completion Progress</p>
                </FlexRowAlignCenter>

                <Flex style={{ gap: "3.5rem", alignItems: "center", height: "100%" }} className={br.mdDownColumn}>
                    <ProgressPieChart completionStats={null} radius={100} thickness={40} />
                    <FlexColumn
                        style={{
                            gap: "1.5rem",
                        }}>
                        <FlexColumn style={{ gap: "0.8rem" }}>
                            {isOnlySetDraft && (
                                <p className="secondaryTextBold1">Your only flashcard set is a draft!</p>
                            )}
                            <p className="secondaryText1">
                                {isOnlySetDraft
                                    ? "Once you create the file, your class can start to study it."
                                    : "Create your first flashcard set & invite students to track progress."}
                            </p>
                        </FlexColumn>
                        {!isOnlySetDraft && (
                            <CircularRectTextButton
                                onClick={() => setIsAddClassFilePopupOpen(true)}
                                className="bodyBold2"
                                sx={{
                                    backgroundColor: course.color,
                                    color: isDarkColor(course.color) ? themeColors.pureWhite : themeColors.pureBlack,
                                    padding: "0.8rem 1.6rem",
                                    width: "fit-content",
                                }}>
                                add flashcard set
                            </CircularRectTextButton>
                        )}
                    </FlexColumn>
                </Flex>
            </FlexColumn>
            <LazyLoaded load={isAddClassFilePopupOpen}>
                <AddClassFilePopup
                    isOpen={isAddClassFilePopupOpen}
                    onClose={() => setIsAddClassFilePopupOpen(false)}
                    classId={course.classId}
                    openMoveFileToClassPopup={() => {
                        setIsAddClassFilePopupOpen(false);
                        setIsMoveFileToClassPopupOpen(true);
                    }}
                    itemType={ItemType.FLASHCARDSET}
                />
            </LazyLoaded>
            <LazyLoaded load={isMoveFileToClassPopupOpen}>
                <MoveFileToClassPopup
                    isOpen={isMoveFileToClassPopupOpen}
                    onClose={() => setIsMoveFileToClassPopupOpen(false)}
                    classId={course.classId}
                />
            </LazyLoaded>
        </>
    );
};

const CompletionProgress = ({
    classId,
    flashcardSet,
    onSeeMoreClick,
    onProgressClick,
    containerStyle,
    className,
}: {
    classId: string;
    flashcardSet: FlashcardSet | null | undefined;
    onSeeMoreClick: () => void;
    onProgressClick: (progress: StudySessionProgressEnum) => void;
    containerStyle?: React.CSSProperties;
    className?: string;
}) => {
    const { completionStats } = useFlashcardSetCompletion({
        classId,
        flashcardSetId: flashcardSet?.flashcardSetId,
    });

    if (!flashcardSet || flashcardSet?.draft) {
        return (
            <EmptyState
                containerStyle={containerStyle}
                className={className}
                classId={classId}
                isOnlySetDraft={!!flashcardSet?.draft}
            />
        );
    }

    return (
        <FlexColumn
            className={className}
            style={{
                flex: 1,
                backgroundColor: themeColors.neutralWhite,
                borderRadius: borderRadius.card,
                padding: "1.6rem",
                ...containerStyle,
            }}>
            <FlexRowAlignCenter style={{ justifyContent: "space-between", marginBottom: "1rem" }}>
                <p className="bodyBold2">Completion Progress</p>
                <CircularRectTextButton
                    onClick={onSeeMoreClick}
                    sx={{
                        marginLeft: "2rem",
                        border: `1px solid ${themeColors.neutral1}`,
                        width: "9.8rem",
                        height: "3.5rem",
                    }}>
                    <span className="secondaryText2">see more</span>
                    <ArrowRight
                        strokeWidth={strokeWidth.normal}
                        size={iconSizes.SM_S}
                        style={{ marginLeft: "0.5rem" }}
                    />
                </CircularRectTextButton>
            </FlexRowAlignCenter>

            <FlexRowAlignCenter
                style={{ gap: "2rem 3.5rem", alignItems: "center", height: "100%" }}
                className={br.mdDownColumn}>
                <ProgressPieChart completionStats={completionStats} radius={81} thickness={100 * 0.4} />

                <FlexColumn style={{ flex: 1 }}>
                    <p className="bodyBold2">{flashcardSet?.title}</p>
                    <div style={{ marginBottom: "1.2rem" }} />

                    <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: spacing.MD }}>
                        <ProgressNumBoxes completionStats={completionStats} onProgressClick={onProgressClick} />
                    </div>
                </FlexColumn>
            </FlexRowAlignCenter>
        </FlexColumn>
    );
};

export default CompletionProgress;
