import React, { forwardRef } from "react";
// eslint-disable-next-line no-restricted-imports
import { SxProps } from "@mui/material";
import Box from "@mui/material/Box";
import { SearchIcon } from "lucide-react";
import { iconSizes, strokeWidth } from "@/utils/iconProps";
import { themeColors } from "@/utils/themeColors";

type InputWithSearchbarIconProps = {
    containerStyle?: React.CSSProperties;
    adornmentProps?: {
        style?: React.CSSProperties;
        strokeWidth?: number;
    };
    noIcon?: boolean;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & { sx: SxProps };

const InputWithSearchbarIcon = forwardRef<HTMLInputElement, InputWithSearchbarIconProps>(
    ({ adornmentProps, noIcon, containerStyle, ...props }, ref) => {
        return (
            <div style={{ position: "relative", ...containerStyle }}>
                <Box
                    component="input"
                    ref={ref}
                    {...props}
                    sx={{
                        fontFamily: "var(--knowt-font-name)",
                        height: "5rem",
                        fontWeight: "600",
                        color: "var(--color-neutral-black)",
                        backgroundColor: "var(--color-card-primary)",
                        padding: noIcon ? "0 15px 0 25px" : "0 15px 0 45px",
                        border: "none",
                        outline: "none",
                        borderRadius: 999,
                        "&:focus": {
                            borderColor: themeColors.primary,
                        },
                        transition: "border-color 0.2s",
                        ...props.sx,
                    }}
                />
                {!noIcon && (
                    <SearchIcon
                        size={iconSizes.MD}
                        style={{
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            left: "1.3rem",
                            ...adornmentProps?.style,
                        }}
                        strokeWidth={adornmentProps?.strokeWidth ?? strokeWidth.normal}
                    />
                )}
            </div>
        );
    }
);

InputWithSearchbarIcon.displayName = "InputWithSearchbarIcon";

export default InputWithSearchbarIcon;
