import { client } from "../../utils/SyncUtils";
import { createRating as createRatingMutation, updateRating as updateRatingMutation } from "@/graphql/mutations";
import { getNoteRatingStats, getRating } from "@/graphql/queries";
import { scrapeEmptyFields } from "@/utils/dataCleaning";
import { platform } from "@/platform";
import { CreateRatingInput, GetNoteRatingStatsInput, GetRatingInput, UpdateRatingInput } from "@/graphql/schema";

const cleanRating = ratingObj => {
    const { noteId, userId, noteOwnerId, message, rating } = ratingObj;

    return scrapeEmptyFields({
        noteId,
        userId,
        noteOwnerId,
        message,
        rating,
    });
};

export const createRating = async (ratingObj: CreateRatingInput) => {
    const { log } = await platform.analytics.logging();

    return await client
        .mutate({
            mutation: createRatingMutation,
            variables: {
                input: cleanRating(ratingObj),
            },
        })
        .catch(e => log("ERR", e));
};

export const updateRating = async (ratingObj: UpdateRatingInput) => {
    const { log } = await platform.analytics.logging();

    return await client
        .mutate({
            mutation: updateRatingMutation,
            variables: {
                input: cleanRating(ratingObj),
            },
        })
        .catch(e => log("ERR", e));
};

export const fetchUserRating = async ({ noteId, userId }: GetRatingInput) => {
    if (!noteId || !userId) {
        return null;
    }

    const { log } = await platform.analytics.logging();

    return await client
        .query({
            query: getRating,
            variables: {
                input: {
                    noteId,
                    userId,
                },
            },
        })
        .then(data => data.data.getRating)
        .catch(e => {
            log("ERR", e);
            return null;
        });
};

export const fetchNoteRatingStats = async ({ noteId }: GetNoteRatingStatsInput) => {
    const { log } = await platform.analytics.logging();

    return await client
        .query({
            query: getNoteRatingStats,
            variables: {
                input: {
                    noteId,
                },
            },
        })
        .then(data => data.data.getNoteRatingStats)
        .catch(e => {
            log("ERR", e);
            return null;
        });
};
