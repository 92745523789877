import clsx from "clsx";
import React from "react";
import CircularButton from "../CircularButton";
import styles from "./tileCards.module.css";
import { ConditionalLink } from "@/components/ConditionalLink";
import { BlackTooltip } from "@/components/BlackTooltip";

export type TileCardsItem = {
    key?: string;
    leftComponent: string | React.ReactNode;
    rightComponent?: string | React.ReactNode;
    hoverText?: string;
    style?: React.CSSProperties;
    href?: string;
    onClick?: () => void;
    isDisabled?: boolean;
};

type TileCardsProps = {
    items: TileCardsItem[];
    style: React.CSSProperties;
    className?: string;
};

/**
 * NOTE: Use `style` to default style of all cards
 * NOTE: Use `item.style` to override default style of a card
 */
const TileCards = ({ items, style, className }: TileCardsProps) => {
    return (
        <React.Fragment>
            {items.map((item, i) => {
                if (item.isDisabled) return null;

                const cardKey = (() => {
                    if (typeof item.leftComponent === "string") {
                        return item.leftComponent + i;
                    } else if (item.href) {
                        return item.href + i;
                    }
                    return i;
                })();

                return (
                    <ConditionalLink key={cardKey} condition={!item.onClick && Boolean(item.href)} href={item.href}>
                        <BlackTooltip title={item.hoverText}>
                            <CircularButton
                                component={item.href ? "section" : "button"}
                                className={clsx(styles.card, className)}
                                sx={{
                                    textAlign: "left",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    borderRadius: "1.7rem",
                                    border: `2px solid var(--color-neutral-1)`,
                                    padding: "1.4rem",
                                    justifyContent: "space-around",
                                    minWidth: "5rem",
                                    minHeight: "7rem",
                                    cursor: "pointer",
                                    gap: "1rem",
                                    backgroundColor: "var(--color-neutral-white)",
                                    ...style,
                                    ...item.style,
                                }}
                                onClick={() => item.onClick && item.onClick()}>
                                <div className={styles.left} style={{ fontFamily: "var(--knowt-font-name)" }}>
                                    {item.leftComponent}
                                </div>
                                <div className={styles.right} style={{ fontFamily: "var(--knowt-font-name)" }}>
                                    {item.rightComponent}
                                </div>
                            </CircularButton>
                        </BlackTooltip>
                    </ConditionalLink>
                );
            })}
        </React.Fragment>
    );
};

export default TileCards;
