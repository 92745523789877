import React, { forwardRef } from "react";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";

type TextTooltipProps = {
    displayText: string;
    tooltipText: string;
    displayTextStyle?: React.CSSProperties;
} & Omit<TooltipProps, "children" | "title">;

const TextTooltip = forwardRef<HTMLDivElement, TextTooltipProps>(
    ({ displayText, tooltipText, displayTextStyle, ...MuiTooltipProps }, ref) => {
        return (
            <Tooltip
                sx={{ fontSize: "1.1rem" }}
                title={tooltipText}
                disableInteractive={true}
                disableFocusListener={false}
                disableHoverListener={false}
                disableTouchListener={false}
                {...MuiTooltipProps}>
                <div ref={ref} style={displayTextStyle}>
                    {displayText}
                </div>
            </Tooltip>
        );
    }
);

TextTooltip.displayName = "TextTooltip";

export default TextTooltip;
