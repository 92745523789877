import { useBreakPoints } from "@/hooks/styles/useBreakpoints";
import { themeColors } from "@/utils/themeColors";
import { useFlashcardSet } from "@knowt/syncing/hooks/flashcards/useFlashcards";
import { useRouter } from "next13-progressbar";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import FlashcardSetOptionsMenu from "@/components/cards/UserContentCard/components/FlashcardSetOptionsMenu";
import { FlexRowAlignCenter } from "@/components/Flex";
import NavBar from "@/features/NavBar";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { LocalUser } from "@knowt/syncing/hooks/user/types";
import { UNTITLED } from "@knowt/syncing/utils/dataCleaning";
import WithOptionsMenu from "@/components/WithOptionsMenu";
import { borderRadius } from "@/utils/borderRadius";
import { STUDY_DROPDOWN_OPTIONS } from "@/components/StudyNavbar/styled/StudyNavbarDropdown";

const SecondaryNavbar = ({ flashcardSetId }: { flashcardSetId: string }) => {
    const trigger = useScrollTrigger({ threshold: 1000, disableHysteresis: true });
    const { flashcardSet } = useFlashcardSet({ flashcardSetId });

    const renderTitle = () => {
        const title = flashcardSet?.title || UNTITLED;

        return (
            <div
                className="heading4"
                style={{
                    flex: 1,
                    fontSize: title.length < 25 ? "2.5rem" : "2rem",
                    display: "-webkit-box",
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    lineHeight: "3rem",
                }}>
                {title}
            </div>
        );
    };

    if (!trigger) return null;

    return (
        <FlexRowAlignCenter
            style={{
                backgroundColor: themeColors.background,
                height: "var(--navbar-height)",
                padding: "1.6rem 3.6rem",
                width: "100%",
                position: "sticky",
                top: 0,
                zIndex: 999,
                justifyContent: "space-between",
                gap: "2rem",
                boxShadow: "0px 3px 35px 0px rgba(0, 0, 0, 0.10)",
            }}>
            {renderTitle()}
            <FlexRowAlignCenter style={{ flex: 1, justifyContent: "flex-end", gap: "1rem" }}>
                <StudyButton flashcardSetId={flashcardSetId} />
                <FlashcardSetOptionsMenu
                    flashcardSet={flashcardSet}
                    parentRef={null}
                    btnSx={{ backgroundColor: themeColors.neutralWhite, width: "3.8rem", height: "3.8rem" }}
                />
            </FlexRowAlignCenter>
        </FlexRowAlignCenter>
    );
};

const StudyButton = ({ flashcardSetId }: { flashcardSetId: string }) => {
    const router = useRouter();
    const studyOptionsArr = Object.values(STUDY_DROPDOWN_OPTIONS);

    const options = studyOptionsArr.map(o => ({
        ...o,
        onClick: () => {
            router.push(`/study/flashcards/${flashcardSetId}/${o.hrefSuffix}`);
        },
    }));

    return (
        <WithOptionsMenu
            options={options}
            menuItemProps={{
                sx: { fontSize: "1.5rem", fontWeight: "600", padding: "8px 20px" },
            }}
            menuProps={{
                PaperProps: {
                    sx: { borderRadius: borderRadius.smallModal, marginTop: "1rem", width: "35rem" },
                },
                anchorOrigin: { vertical: "bottom", horizontal: "right" },
                transformOrigin: { vertical: "top", horizontal: "right" },
            }}>
            {({ openMenu }) => (
                <CircularRectTextButton
                    onClick={openMenu}
                    className={"bodyBold2"}
                    sx={{
                        backgroundColor: themeColors.primary,
                        height: "3.8rem",
                        padding: "0 2.4rem",
                        width: "10rem",
                        color: themeColors.primary2,
                    }}>
                    Study
                </CircularRectTextButton>
            )}
        </WithOptionsMenu>
    );
};

const FlashcardOverviewNavbar = ({ serverUser, flashcardSetId }: { serverUser: LocalUser; flashcardSetId: string }) => {
    const { mdDown } = useBreakPoints();

    return (
        <>
            <NavBar serverUser={serverUser} outerContainerStyle={{ position: mdDown ? "sticky" : "static" }} />
            {!mdDown && <SecondaryNavbar flashcardSetId={flashcardSetId} />}
        </>
    );
};

export default FlashcardOverviewNavbar;
