import Tooltip, { TooltipProps } from "@mui/material/Tooltip";

export const BlackTooltip = ({ children, ...props }: { children: React.ReactNode } & TooltipProps) => {
    return (
        <Tooltip
            componentsProps={{
                tooltip: {
                    sx: {
                        backgroundColor: "black",
                        fontSize: "1.2rem",
                    },
                },
            }}
            {...props}>
            {children}
        </Tooltip>
    );
};
