import { AuthEvent } from "@/features/Auth";
import FilledTextButton from "./FilledTextButton";

import { useCheckAuth } from "@/hooks/useCheckAuth";

export const AddRatingButton = ({
    isRated,
    setRatingPopupOpen,
}: {
    isRated: boolean;
    setRatingPopupOpen: (open: boolean) => void;
}) => {
    const { checkAuth } = useCheckAuth();

    return (
        <FilledTextButton
            onClick={() =>
                checkAuth(() => setRatingPopupOpen(true), {
                    event: AuthEvent.RATE_ITEM,
                })
            }
            text={isRated ? "update rating" : "add a rating"}
            sx={{ borderWidth: 1 }}
        />
    );
};

export default AddRatingButton;
