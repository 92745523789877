import { Star } from "lucide-react";
import CircularRectTextButton, {
    CircularRectTextButtonProps,
} from "@/components/CircularButton/styled/CircularRectTextButton";
import { borderRadius } from "@/utils/borderRadius";
import { iconSizes } from "@/utils/iconProps";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";

type ToggleStarButtonProps = CircularRectTextButtonProps & {
    allStarred: boolean;
    numOfFlashcards: number;
    onClick: () => void;
};

const ToggleStarButton = ({ allStarred, numOfFlashcards, onClick, sx, ...props }: ToggleStarButtonProps) => {
    return (
        <CircularRectTextButton
            className="secondaryText1"
            onClick={onClick}
            {...props}
            sx={{
                width: "fit-content",
                height: "5rem",
                borderRadius: borderRadius.shortInput,
                color: themeColors.neutralBlack,
                backgroundColor: themeColors.neutralWhite,
                gap: spacing.XS,
                padding: `0 ${spacing.SM}`,
                ...sx,
            }}>
            <Star
                size={iconSizes.MD}
                color={allStarred ? themeColors.icon2 : undefined}
                fill={allStarred ? themeColors.icon2 : "transparent"}
            />
            {`${allStarred ? "Unstar" : "Star"}  ${numOfFlashcards === 1 ? "this one" : "these " + numOfFlashcards}`}
        </CircularRectTextButton>
    );
};

export default ToggleStarButton;
