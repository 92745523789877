import Circular from "@/components/Circular";
import React from "react";
import { StudySessionProgressEnum } from "@knowt/syncing/graphql/schema";
import { FlexColumn } from "@/components/Flex";
import { spacing } from "@/utils/spacing";
import { getProgressLabel } from "@knowt/syncing/hooks/study/statsUtils";
import { getBackgroundColor, getColor } from "@/utils/progressPill";
import { iconSizes } from "@/utils/iconProps";
import { ArrowRightIcon } from "lucide-react";
import Skeleton from "@mui/material/Skeleton";

const EmptyState = () => {
    const zeroCompletionStats = {
        [StudySessionProgressEnum.NEW]: 0,
        [StudySessionProgressEnum.LEARNING]: 0,
        [StudySessionProgressEnum.REVIEWING]: 0,
        [StudySessionProgressEnum.MASTERED]: 0,
    };

    return (
        <>
            {Object.keys(zeroCompletionStats).map((key: StudySessionProgressEnum) => (
                <ProgressNumBox
                    isSelected={false}
                    key={key}
                    label={getProgressLabel(key)}
                    count={zeroCompletionStats[key]}
                    color={getColor(key)}
                    backgroundColor={getBackgroundColor(key)}
                    countStyle={{ borderRadius: "99rem", padding: "1rem 1.5rem" }}
                />
            ))}
        </>
    );
};

const ProgressNumBox = ({
    label,
    count,
    color,
    backgroundColor,
    isSelected,
    onClick,
    countStyle,
}: {
    label: string;
    count: number;
    color: string;
    backgroundColor: string;
    isSelected: boolean;
    onClick?: () => void;
    countStyle?: React.CSSProperties;
}) => {
    return (
        <FlexColumn onClick={onClick} style={{ gap: spacing.XS, flex: 1, cursor: onClick ? "pointer" : "default" }}>
            <Circular
                className={"secondaryTextBold1"}
                style={{
                    fontSize: "1.4rem",
                    padding: "0.8rem",
                    borderRadius: "10px",
                    backgroundColor,
                    color: color,
                    width: "fit-content",
                    minWidth: "2.8rem",
                    columnGap: spacing.XXS,
                    outline: isSelected ? `2px solid ${color}` : undefined,
                    ...countStyle,
                }}>
                {count}
                {!!onClick && <ArrowRightIcon size={iconSizes.SM} color={color} />}
            </Circular>
            <p className="secondaryText2 ellipsisText">{label}</p>
        </FlexColumn>
    );
};

export const ProgressNumBoxes = ({
    completionStats,
    studyProgressFilter,
    onProgressClick,
}: {
    completionStats: Record<StudySessionProgressEnum, number> | null | undefined;
    studyProgressFilter?: StudySessionProgressEnum | null;
    onProgressClick?: (progress: StudySessionProgressEnum) => void;
}) => {
    const isLoading = completionStats === undefined;

    if (completionStats === null) return <EmptyState />;

    if (isLoading) {
        return (
            <>
                {Array.from({ length: 4 }).map((_, index) => (
                    <FlexColumn key={index}>
                        <Skeleton width={32} height={43} />
                        <Skeleton width={75} height={14} />
                    </FlexColumn>
                ))}
            </>
        );
    }

    return (
        <>
            {Object.keys(completionStats).map((key: StudySessionProgressEnum) => (
                <ProgressNumBox
                    key={key}
                    label={getProgressLabel(key)}
                    count={completionStats[key]}
                    color={getColor(key)}
                    backgroundColor={getBackgroundColor(key)}
                    isSelected={studyProgressFilter === key}
                    onClick={() => onProgressClick?.(key)}
                />
            ))}
        </>
    );
};

export default ProgressNumBoxes;
