import { ClassSection, Flashcard, FlashcardSet, StudySessionProgressEnum } from "@/graphql/schema";
import { getFlashcardsMastery, getFlashcardsStudyAnalytics, getOverallFlashcardSetCompletion } from "./statsUtils";
import { useClassFlashcardStudyStates, useClassStudySessions } from "./useStudySession";
import { useClass } from "../classes/useClass";
import { useCallback, useMemo, useState } from "react";
import { useCurrentUser } from "../user/useCurrentUser";

export const useFlashcardSetCompletion = ({
    classId,
    flashcardSetId,
    studentIds: _studentIds,
    isEnabled = true,
}: {
    classId: string | null | undefined;
    flashcardSetId: string | null | undefined;
    studentIds?: string[];
    isEnabled?: boolean;
}) => {
    const { course } = useClass({ classId });

    const { studySessions: _studySessions, isLoading } = useClassStudySessions({
        classId,
        itemId: flashcardSetId,
        isEnabled,
    });

    const completionStats: Record<StudySessionProgressEnum, number> | null | undefined = useMemo(() => {
        if (!flashcardSetId) {
            // show empty state
            return null;
        }

        if (!course || !_studySessions) {
            // show loading
            return undefined;
        }

        const courseMembers = course.members.map(({ userId }) => userId);
        const studentIds = _studentIds || courseMembers;

        const studySessions = _studySessions.filter(session => studentIds.includes(session.userId));

        const _completionStats = getOverallFlashcardSetCompletion({ studySessions });

        // for students that don't have any study sessions, fill with 0.
        const missingStudents =
            courseMembers.length - _studySessions.filter(session => courseMembers.includes(session.userId)).length;

        return {
            ..._completionStats,
            [StudySessionProgressEnum.NEW]: missingStudents + _completionStats[StudySessionProgressEnum.NEW],
        };
    }, [flashcardSetId, course, _studySessions, _studentIds]);

    return {
        completionStats,
        isLoading,
    };
};

export const useFlashcardSetStudyAnalytics = ({
    flashcardSet,
    selectedSection,
    isEnabled = true,
}: {
    flashcardSet?: FlashcardSet | null;
    selectedSection?: ClassSection | null;
    isEnabled?: boolean;
}) => {
    const { userId } = useCurrentUser();

    const { course } = useClass({ classId: flashcardSet?.classId });

    const studentIds = course?.members
        .filter(({ pending, sections }) => !pending && (!selectedSection || sections.includes(selectedSection.id)))
        .map(({ userId }) => userId);

    const { completionStats } = useFlashcardSetCompletion({
        classId: course?.classId,
        flashcardSetId: flashcardSet?.flashcardSetId,
        studentIds: studentIds,
        isEnabled,
    });

    const [editFlashcardPopupOpen, setEditFlashcardPopupOpen] = useState(false);
    const [flashcardToEdit, setFlashcardToEdit] = useState<Flashcard>();

    const readOnly = flashcardSet?.userId !== userId;

    const showEditFlashcard = useCallback((flashcard: Flashcard) => {
        setEditFlashcardPopupOpen(true);
        setFlashcardToEdit(flashcard);
    }, []);

    const { flashcardStudyStates } = useClassFlashcardStudyStates({
        classId: flashcardSet?.classId,
        flashcardSetId: flashcardSet?.flashcardSetId,
        isEnabled,
    });

    const flashcardsStudyAnalytics = getFlashcardsStudyAnalytics({
        flashcardStudyStates,
        flashcardSet,
        userIds: studentIds,
    });

    const flashcardsMastery = getFlashcardsMastery({
        flashcardsStudyAnalytics,
    });

    return {
        course,
        editFlashcardPopupOpen,
        flashcardToEdit,
        readOnly,
        completionStats,
        studentIds,
        flashcardsMastery,
        flashcardsStudyAnalytics,
        showEditFlashcard,
        setEditFlashcardPopupOpen,
        setFlashcardToEdit,
    };
};
