import { examNameToUrlComponent } from "@knowt/syncing/hooks/exams/utils";
import { subjectNameToUrlComponent } from "@knowt/syncing/hooks/subjects/utils";
import { TagValueWithMetadata } from "@knowt/syncing/hooks/tags/useTags";
// eslint-disable-next-line no-restricted-imports
import type { SxProps } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useEffect, useRef, useState } from "react";
import ConditionalTooltip from "./wrappers/ConditionalTooltip";
import { themeColors } from "@/utils/themeColors";
import TextTooltip from "@/components/TextTooltip";
import { FlexColumnAlignJustifyCenter } from "@/components/Flex";

export const getTagHref = (tagValueWithMetadata: TagValueWithMetadata) => {
    const { type, value, id, category, subject, exam } = tagValueWithMetadata;
    const defaultHref = `/search?type=&q=${value}`;
    let examType = value.split(" ")[0];

    switch (type) {
        case "subject":
            return `/subject/${subjectNameToUrlComponent(category)}/${subjectNameToUrlComponent(value)}-flashcards`;
        case "topic":
            return `/subject/${subjectNameToUrlComponent(category)}/${subjectNameToUrlComponent(
                subject
            )}/${subjectNameToUrlComponent(value)}-flashcards`;
        case "school":
            return `/search?type=&schoolId=${id}`;
        case "exam": {
            return `/exams/${examType}/${examNameToUrlComponent(value)}`;
        }
        case "examUnit": {
            if (!exam) return defaultHref;
            examType = exam.split(" ")[0];
            return `/exams/${examType}/view/${examNameToUrlComponent(exam)}/${examNameToUrlComponent(
                value
            )}-flashcards`;
        }
        case "grade":
        case "custom":
        default:
            return defaultHref;
    }
};

const isOverflownByWidthOrHeight = (
    childEl: Element,
    parentEl: Element,
    offsides = { rightOffside: 0, bottomOffside: 0 }
) => {
    const parRect = parentEl.getBoundingClientRect();
    const childRect = childEl.getBoundingClientRect();

    return (
        childRect.right > parRect.right - offsides.rightOffside ||
        childRect.bottom > parRect.bottom - offsides.bottomOffside
    );
};

type TagBoxWithCutOffCalculationProps = {
    children: React.ReactNode;
    parentRef: React.MutableRefObject<HTMLDivElement | null>;
    onTagCutOff: () => void;
    recalculateDeps: unknown[];
    sx?: SxProps;
    href?: string;
};

const TagBoxWithCutOffCalculation = ({
    children,
    parentRef,
    onTagCutOff,
    recalculateDeps,
    sx,
    href,
}: TagBoxWithCutOffCalculationProps) => {
    const tagBoxRef = useRef(null);
    const [shouldBeHidden, setShouldBeHidden] = useState(false);

    useEffect(() => {
        if (shouldBeHidden || !parentRef.current || !tagBoxRef.current) {
            return;
        }

        if (
            isOverflownByWidthOrHeight(
                tagBoxRef.current,
                parentRef.current,
                { rightOffside: 25, bottomOffside: 0 } /* approx width of `cutOffTags.length` */
            )
        ) {
            setShouldBeHidden(true);
            onTagCutOff();
        }

        // remove eslint spread array warning,
        // and we only want to re-run this effect when the intended `recalculateDeps` changes.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...recalculateDeps]);

    return (
        <ConditionalTooltip tooltip={`view other notes and flashcards on ${children}`}>
            <Box
                component={FlexColumnAlignJustifyCenter}
                as={href ? "a" : "p"}
                ref={tagBoxRef}
                data-testid="tag-box"
                style={{ display: shouldBeHidden ? "none" : "inline" }}
                href={href}
                sx={{
                    textDecoration: "none",
                    backgroundColor: "var(--color-tag-back)",
                    borderRadius: "9999px",
                    padding: "0.85rem 1.5rem",
                    minWidth: 0,
                    overflow: "hidden",
                    color: "var(--color-neutral-black)",
                    fontSize: "1.3rem",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    flexShrink: 0,
                    transition: "background-color 0.07s ease-in-out", // incase want to add hoverable bg
                    cursor: "pointer",
                    ...sx,
                }}>
                {children}
            </Box>
        </ConditionalTooltip>
    );
};

type renderTagPros = (props: { value: string; sx?: SxProps; key?: string; href?: string }) => React.ReactNode;

type TagsProps = {
    children: ({
        ref,
        renderTag,
        renderHiddenTagsCount,
    }: {
        ref: React.MutableRefObject<HTMLDivElement | null>;
        renderTag: renderTagPros;
        renderHiddenTagsCount: () => React.ReactNode;
    }) => React.ReactNode;
};

const Tags = ({ children }: TagsProps) => {
    const tagsContainerRef = useRef(null);

    const [cutOffTagList, setCutOffTagList] = useState<string[]>([]);

    const renderTag: renderTagPros = ({ value, sx, key, href }) => {
        return (
            <TagBoxWithCutOffCalculation
                key={key}
                recalculateDeps={[cutOffTagList]} // on num of tags changed, recalculate the overflow
                parentRef={tagsContainerRef}
                onTagCutOff={() => {
                    setCutOffTagList(c => [...new Set([...c, value])]);
                }}
                sx={sx}
                href={href}>
                {value}
            </TagBoxWithCutOffCalculation>
        );
    };

    const renderHiddenTagsCount = () => {
        if (!cutOffTagList.length) return null;

        return (
            <TextTooltip
                tooltipText={cutOffTagList.filter(t => !!t).join(", ")}
                displayText={`+${cutOffTagList.length}`}
                displayTextStyle={{ fontWeight: "600", fontSize: "1.5rem", color: themeColors.neutralBlack }}
                arrow
            />
        );
    };

    return <>{children({ ref: tagsContainerRef, renderTag, renderHiddenTagsCount })}</>;
};

export default Tags;
