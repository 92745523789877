import { useSWRImmutable } from "@knowt/syncing/hooks/swr";
import { fetchNoteRatingStats, fetchUserRating } from "./utils";
import { useCurrentUser } from "@/hooks/user/useCurrentUser";
import { Rating, RatingStats } from "@knowt/syncing/graphql/schema";
import { populateCacheWithFallbackData } from "@/hooks/swr";

export const useNoteRatingStats = (noteId: string, fallbackData: RatingStats = undefined) => {
    const { data, error, mutate, isValidating } = useSWRImmutable(
        noteId && ["noteRatingStats", noteId],
        async () => {
            return await fetchNoteRatingStats({ noteId });
        },
        {
            fallbackData,
            use: [populateCacheWithFallbackData],
        }
    );

    return { ratingStats: data, error, mutate, isValidating };
};

export const useUserRating = (noteId: string, fallbackData: Rating = undefined) => {
    const { userId } = useCurrentUser();

    const { data, error, mutate, isValidating } = useSWRImmutable(
        noteId && userId && ["userRating", noteId, userId],
        async () => {
            return await fetchUserRating({ noteId, userId });
        },
        {
            fallbackData,
            use: [populateCacheWithFallbackData],
        }
    );

    return { userRating: data, error, mutate, isValidating };
};
