"use client";

import React, { useEffect } from "react";
import GuidedWalkthrough, { useGuidedWalkthrough, WALKTHROUGH_LABELS } from "@/features/GuidedWalkthrough";

export const WALKTHROUGH_SET_SECTION_PILL = "walkthrough-invite-btn";
export const WALKTHROUGH_SET_PROGRESS_CARD = "walkthrough-add-file-btn";
export const WALKTHROUGH_SET_LIST = "walkthrough-section-pill";
export const WALKTHROUGH_SET_DUPLICATE_BTN = "walkthrough-duplicate-btn";

export const ClassOwnSetWalkthrough = () => {
    const { isWalkthroughActive, activateWalkthrough, hasWalkthroughTaken, doneWalkthrough } = useGuidedWalkthrough({
        label: WALKTHROUGH_LABELS.CLASS_OWN_SET_INTRO,
    });

    useEffect(() => {
        if (hasWalkthroughTaken()) return;
        activateWalkthrough({});
    }, [hasWalkthroughTaken, activateWalkthrough]);

    return (
        <GuidedWalkthrough
            onFinish={() => doneWalkthrough()}
            isActive={isWalkthroughActive}
            steps={[
                {
                    title: "See which sections can view this file",
                    content: "Modify sharing settings at any time by clicking “Manage sharing”.",
                    placement: "top-start",
                    target: "." + WALKTHROUGH_SET_SECTION_PILL,
                    numOfSteps: 3,
                },
                {
                    title: "See how your class is doing on these flashcards ",
                    content:
                        "Progress represents mastery across all study modes (except flashcards mode). Students that haven’t yet studied this set will start at 0%, and once mastered they’ll reach 100%.",
                    placement: "right",
                    target: "." + WALKTHROUGH_SET_PROGRESS_CARD,
                    numOfSteps: 3,
                },
                {
                    title: "View student mastery of specific flashcards",
                    content:
                        "See what percentage of students in your class have mastered this flashcard. Click see more for more details.",
                    placement: "top",
                    target: "." + WALKTHROUGH_SET_LIST,
                    numOfSteps: 3,
                },
            ]}
        />
    );
};

export const ClassStudentViewPointWalkthrough = () => {
    const { isWalkthroughActive, activateWalkthrough, hasWalkthroughTaken, doneWalkthrough } = useGuidedWalkthrough({
        label: WALKTHROUGH_LABELS.CLASS_NOT_OWN_SET_INTRO,
    });

    useEffect(() => {
        if (hasWalkthroughTaken()) return;
        activateWalkthrough({});
    }, [hasWalkthroughTaken, activateWalkthrough]);

    return (
        <GuidedWalkthrough
            onFinish={() => doneWalkthrough()}
            isActive={isWalkthroughActive}
            steps={[
                {
                    title: "Add someone else’s flashcard set to a class",
                    content: "You can use this button on all public flashcard sets to add it to a class.",
                    placement: "bottom-start",
                    target: "." + WALKTHROUGH_SET_SECTION_PILL,
                    onClick: () => doneWalkthrough(),
                },
            ]}
        />
    );
};

export const NonClassSetWalkthrough = () => {
    const { isWalkthroughActive, activateWalkthrough, hasWalkthroughTaken, doneWalkthrough } = useGuidedWalkthrough({
        label: WALKTHROUGH_LABELS.SET_OVERVIEW_NON_CLASS,
    });

    useEffect(() => {
        if (hasWalkthroughTaken()) return;
        activateWalkthrough({});
    }, [hasWalkthroughTaken, activateWalkthrough]);

    return (
        <GuidedWalkthrough
            onFinish={() => doneWalkthrough()}
            isActive={isWalkthroughActive}
            steps={[
                {
                    title: "Add your flashcard set to a class",
                    content: "Move your own flashcard set into a class.",
                    placement: "bottom-start",
                    target: "." + WALKTHROUGH_SET_DUPLICATE_BTN,
                    onClick: () => doneWalkthrough(),
                },
            ]}
        />
    );
};
