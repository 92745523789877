import { useSWRImmutable } from "@knowt/syncing/hooks/swr";
import { fetchSchoolsById } from "./utils";

export const useSchool = (schoolId: string) => {
    const { data } = useSWRImmutable(
        schoolId ? ["school", schoolId] : null,
        async ([_, schoolId]) => (await fetchSchoolsById([schoolId]))?.[0] || null
    );

    return { data };
};

export const useSchoolName = (schoolId: string): string | null => {
    const { data: school } = useSchool(schoolId);
    if (typeof school === "undefined") return undefined;
    return school?.[0]?.name || null;
};

export const useSchoolType = (schoolId: string) => {
    const { data: school } = useSchool(schoolId);
    return school?.[0]?.type;
};
