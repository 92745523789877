import { useUserRating } from "@knowt/syncing/hooks/rating/useRating";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { useRouter } from "next13-progressbar";
import { useEffect, useRef, useState } from "react";
import { getPrevPath } from "@/utils/pathUtils";
import { RatingStats } from "@knowt/syncing/graphql/schema";

const LS_KEY_NUMBER_OF_TIMES_POPUP_WAS_DISPLAYED = "numberOfTimesRatingPopupWasDisplayed";

const shouldTheRatingBeShowWhenReturningFrom = previousPath => {
    return previousPath?.includes("/study/flashcards/");
};

// const shouldTheRatingBeShowWhenNavigatingTo = nextPath => {
//     return !(nextPath?.includes("/study/flashcards/") || nextPath?.includes("/note/"));
// };

const wasThePopupDisplayedLessThanTwoTimes = noteId => {
    const numberOfTimesPopupWasDisplayedForThisNote = `${LS_KEY_NUMBER_OF_TIMES_POPUP_WAS_DISPLAYED}${noteId}`;
    const numberOfTimesPopupWasDisplayed = localStorage.getItem(numberOfTimesPopupWasDisplayedForThisNote);

    return numberOfTimesPopupWasDisplayed ? Number(numberOfTimesPopupWasDisplayed) < 2 : true;
};

const incrementTimesThePopupWasDisplayed = noteId => {
    const numberOfTimesPopupWasDisplayedForThisNote = `${LS_KEY_NUMBER_OF_TIMES_POPUP_WAS_DISPLAYED}${noteId}`;
    const numberOfTimesPopupWasDisplayed = localStorage.getItem(numberOfTimesPopupWasDisplayedForThisNote);

    localStorage.setItem(
        numberOfTimesPopupWasDisplayedForThisNote,
        String(numberOfTimesPopupWasDisplayed ? (Number(numberOfTimesPopupWasDisplayed) || 0) + 1 : 1)
    );
};

// TODO! (Router Events): router events are no longer supported in next 13, make sure this auto save still works!!
export const useRatingWhenExit = ({
    noteId,
    readOnly,
    showAfterReturningFromStudy = false,
    ratingStats = undefined,
}: {
    noteId: string;
    readOnly?: boolean;
    showAfterReturningFromStudy?: boolean;
    ratingStats?: RatingStats;
}) => {
    const router = useRouter();
    const previousPath = getPrevPath();
    const { userId } = useCurrentUser();

    const [ratingPopupOpen, setRatingPopupOpen] = useState(false);
    const [nextRouteToNavigate] = useState(null);

    const { userRating } = useUserRating(noteId);

    const shouldShowRatingOnExit = useRef(false);
    const notRatedYet = !userRating;

    useEffect(() => {
        if (userId) {
            const shouldShowRating =
                showAfterReturningFromStudy &&
                shouldTheRatingBeShowWhenReturningFrom(previousPath) &&
                readOnly &&
                notRatedYet &&
                wasThePopupDisplayedLessThanTwoTimes(noteId);

            setRatingPopupOpen(shouldShowRating);
            if (shouldShowRating) incrementTimesThePopupWasDisplayed(noteId);
        }
    }, [noteId, userId, showAfterReturningFromStudy, previousPath, readOnly, notRatedYet]);

    useEffect(() => {
        let timeout;
        if (readOnly && userId && !userRating && wasThePopupDisplayedLessThanTwoTimes(noteId)) {
            timeout = setTimeout(() => {
                shouldShowRatingOnExit.current = true;
            }, 30_000);
        } else {
            shouldShowRatingOnExit.current = false;
        }
        return () => clearTimeout(timeout);
    }, [readOnly, userId, userRating, noteId]);

    useEffect(() => {
        // const showRatingBeforeExit = () => {
        //     setRatingPopupOpen(true);
        //     shouldShowRatingOnExit.current = false;
        //     incrementTimesThePopupWasDisplayed(noteId);
        //     throw "ignored error: route change intentionally aborted";
        // };
        // const handleBrowseAway = nextPath => {
        //     if (shouldShowRatingOnExit.current && !userRating && shouldTheRatingBeShowWhenNavigatingTo(nextPath)) {
        //         // router.events.emit("routeChangeComplete");
        //         setNextRouteToNavigate(nextPath);
        //         showRatingBeforeExit();
        //     }
        // };
        // router.events.on("routeChangeStart", handleBrowseAway);
        // return () => router.events.off("routeChangeStart", handleBrowseAway);
    }, [noteId, userRating]);

    const onCloseRatingPopup = async () => {
        setRatingPopupOpen(false);
        if (typeof nextRouteToNavigate === "string") {
            await router.push(nextRouteToNavigate);
        }
    };

    return { onCloseRatingPopup, userRating, ratingPopupOpen, setRatingPopupOpen, ratingStats };
};
