import { Flashcard, FlashcardStudyState, StudySessionProgressEnum } from "@knowt/syncing/graphql/schema";
import { FlashcardItem, FlashcardItemCard } from "./types";

export const mixAdsWithFlashcardItems = ({
    items,
    interval = 1,
    maximumAds = 25,
    adCount,
    renderForLessThanInterval = true,
}: {
    items: FlashcardItemCard[];
    interval: number;
    maximumAds?: number;
    adCount?: number;
    renderForLessThanInterval?: boolean;
}) => {
    const resultArray: FlashcardItem[] = items;
    const adAmount = Math.min(adCount ?? Math.floor((items.length - 1) / interval), maximumAds);

    if (resultArray.length >= interval) {
        for (let i = 1; i <= adAmount; i++) {
            resultArray.splice(i * interval + (i - 1), 0, { type: "ad", id: `array-ad-${i}`, index: i });
        }
    } else if (renderForLessThanInterval) {
        resultArray.push({ type: "ad", id: `array-ad-1`, index: 0 });
    }

    return resultArray;
};

export const createFlashcardItemCard = ({
    flashcard,
    flashcardStudyStates,
    starredFlashcardIds,
    toggleFlashcardStarByIds,
}: {
    flashcard: Flashcard;
    flashcardStudyStates?: Record<string, FlashcardStudyState>;
    starredFlashcardIds: string[];
    toggleFlashcardStarByIds: (ids: string[]) => void;
}): FlashcardItemCard => {
    const { flashcardId } = flashcard;

    return {
        type: "card",
        id: `flashcard-${flashcardId}`,
        progress:
            flashcardStudyStates !== undefined
                ? flashcardStudyStates?.[flashcardId]?.progress ?? StudySessionProgressEnum.NEW
                : undefined,
        flashcard,
        isStarred: starredFlashcardIds.includes(flashcardId),
        toggleFlashcardStarByIds,
    };
};
