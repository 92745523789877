"use client";

import MasteryPerFlashcardCard from "./MasteryPerFlashcardCard";
import { Flashcard } from "@knowt/syncing/graphql/schema";
import { FlashcardsMastery } from "@knowt/syncing/hooks/study/statsUtils";
import { FlashcardItem } from "@/features/FlashcardSetOverview";
import { HorizontallyCenteredRepeatableLeaderboardAdSlot } from "@/features/Ads";

const MasteryPerFlashcard = ({
    index,
    item,
    context: {
        readOnly,
        showEditFlashcard,
        toggleFlashcardStarByIds,
        flashcardsMastery,
        setMasteryDetailsPopupIndex,
        starredFlashcards,
    },
}: {
    index: number;
    item: FlashcardItem;
    context: {
        readOnly: boolean;
        showEditFlashcard: (flashcard: Flashcard) => void;
        toggleFlashcardStarByIds: (id: string[]) => void;
        flashcardsMastery: FlashcardsMastery | null | undefined;
        setMasteryDetailsPopupIndex: (index: number) => void;
        starredFlashcards: string[];
    };
}) => {
    return (
        <div style={{ padding: `2rem 0` }}>
            {item.type === "ad" ? (
                <HorizontallyCenteredRepeatableLeaderboardAdSlot
                    containerStyle={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    slotId={item.index}
                />
            ) : (
                <MasteryPerFlashcardCard
                    index={index}
                    flashcard={item.flashcard}
                    readOnly={readOnly}
                    showEditFlashcard={showEditFlashcard}
                    flashcardMastery={flashcardsMastery?.[item.flashcard.flashcardId]}
                    toggleFlashcardStarByIds={toggleFlashcardStarByIds}
                    openDetailsPopup={() => setMasteryDetailsPopupIndex(index)}
                    starredFlashcards={starredFlashcards}
                />
            )}
        </div>
    );
};

export default MasteryPerFlashcard;
