"use client";
import { useFlashcardSet } from "@knowt/syncing/hooks/flashcards/useFlashcards";
import { useFlashcardStudyStates } from "@knowt/syncing/hooks/study/useStudySession";
import { useTrackFlashcardSetView } from "@knowt/syncing/hooks/useViewTracking";
import { LocalUser } from "@knowt/syncing/hooks/user/types";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import {
    Flashcard,
    FlashcardSet,
    FlashcardSetViewer,
    Note,
    StudySession,
    StudySessionProgressEnum,
} from "@knowt/syncing/graphql/schema";
import dynamic from "next/dynamic";
import { useParams } from "next/navigation";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Virtuoso } from "react-virtuoso";
import { SWRConfig, unstable_serialize } from "swr";
import { Flex, FlexColumn } from "@/components/Flex";
import Footer from "@/components/Footer";
import FlashcardSetHeader from "./components/FlashcardSetHeader";
import { FlashcardItemRow } from "./components/FlashcardItemRow";
import styles from "./flashcardSetOverview.module.css";
import { FlashcardItem, FlashcardItemCard } from "./types";
import { createFlashcardItemCard, mixAdsWithFlashcardItems } from "./utils";
import Mixpanel from "@/utils/analytics/Mixpanel";
import { useMounted } from "@/hooks/useMounted";
import { TextToSpeechContextProvider } from "@/contexts/TextToSpeechContext";
import LazyLoaded from "@/components/LazyLoaded";
import { dialogClasses } from "@mui/material/Dialog";
import useDOMEventListener from "@/hooks/useDOMEventListener";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { themeColors } from "@/utils/themeColors";
import Link from "@/components/wrappers/Link";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";
import FlashcardOverviewNavbar from "./components/FlashcardOverviewNavbar";
import { MainContainerAdSlot } from "@/features/Ads";
import { MasteryPerFlashcard } from "@/features/UserContentManagement";
import { useFlashcardSetStudyAnalytics } from "@knowt/syncing/hooks/study/useStudySessionStats";
import { ExploreTopUserContents } from "../LandingPages";
import { spacing } from "@/utils/spacing";
import clsx from "clsx";
import {
    ClassOwnSetWalkthrough,
    ClassStudentViewPointWalkthrough,
    NonClassSetWalkthrough,
    WALKTHROUGH_SET_LIST,
} from "./components/Walkthroughs";
import { useFlashcardSetViewer } from "@/hooks/flashcardSetViewer/useFlashcardSetViewer";

const SignUpPopup = dynamic(() => import("./components/SignUpPopup"));
const EditableFlashcardPopup = dynamic(() => import("@/components/Popup/EditableFlashcardPopup"));

const MasteryPerFlashcardDetailsPopup = dynamic(() =>
    import("@/features/UserContentManagement").then(p => p.MasteryPerFlashcardDetailsPopup)
);

export const AD_SLOT_WIDTH = 320;

type FlashcardSetOverviewPageProps = {
    serverUser: LocalUser;
    serverFlashcardSet: FlashcardSet;
    serverFlashcardSetViewer: FlashcardSetViewer;
    serverStudySession: StudySession | null | undefined;
    topNotes?: Note[];
    topFlashcardSets?: FlashcardSet[];
    parent?: HTMLElement | null;
    hideHeader?: boolean;
};

const MemoizedFlashcardSetOverviewComponent = memo(
    ({
        serverUser,
        serverFlashcardSet,
        serverFlashcardSetViewer,
        serverStudySession,
        topNotes,
        topFlashcardSets,
        parent,
        hideHeader,
        starred: _starred,
        toggleFlashcardStarByIds,
    }: {
        serverUser: LocalUser;
        serverFlashcardSet: FlashcardSet;
        serverFlashcardSetViewer: FlashcardSetViewer;
        serverStudySession: StudySession | null | undefined;
        topNotes?: Note[];
        topFlashcardSets?: FlashcardSet[];
        parent?: HTMLElement | null;
        hideHeader?: boolean;
        starred: string[] | null | undefined;
        toggleFlashcardStarByIds: (flashcardIds: string[], starred: boolean) => void;
    }) => {
        // casting because if it's not null/undefined, it has to be a string array
        // and cannot be a (string|null) array as the type `string[] | null | undefined` suggests
        const starred = _starred as string[] | null | undefined;
        const { lgUp } = useBreakPoints();

        const params = useParams();

        //for media page
        const mediaMode = getMediaMode();

        function getMediaMode() {
            if (typeof window !== "undefined") {
                const path = window.location.pathname;
                return path.includes("media");
            } else {
                return false;
            }
        }

        const { user, organization, userId, canShowAds, isTeacher } = useCurrentUser({
            fallbackData: serverUser,
        });

        const isTeachersClassSet = useMemo(
            () => !!isTeacher && !!serverFlashcardSet.classId && serverFlashcardSet.userId === userId,
            [isTeacher, serverFlashcardSet, userId]
        );

        const mounted = useMounted(2_000);

        const [query, setQuery] = useState("");
        const [activeSection, setActiveSection] = useState<StudySessionProgressEnum>();
        const [filterStarredItems, setFilterStarredItems] = useState(false);
        const [editFlashcardPopupOpen, setEditFlashcardPopupOpen] = useState(false);
        const [flashcardToEdit, setFlashcardToEdit] = useState<Flashcard>();
        const [masteryDetailsPopupIndex, setMasteryDetailsPopupIndex] = useState<number>(-1);

        const flashcardSetId = (params?.flashcardSetId as string) ?? serverFlashcardSet.flashcardSetId;

        const {
            flashcards,
            flashcardSet: _flashcardSet,
            updateFlashcardSetMetaData,
        } = useFlashcardSet({ flashcardSetId: flashcardSetId, fallbackData: serverFlashcardSet });

        // We can safely do this because of serverFlashcardSet
        const flashcardSet = _flashcardSet as FlashcardSet;

        const { flashcardStudyStates } = useFlashcardStudyStates({ flashcardSetId });

        const ownerId = serverFlashcardSet.userId;
        const readOnly = userId !== ownerId;

        const { course, flashcardsMastery, flashcardsStudyAnalytics } = useFlashcardSetStudyAnalytics({
            flashcardSet,
            isEnabled: isTeachersClassSet,
        });

        useTrackFlashcardSetView({ flashcardSetId });

        useEffect(() => {
            Mixpanel.track("Flashcard Set - Visited", {
                flashcardSetId,
                noteId: flashcardSet.noteId,
                isOwner: flashcardSet.userId === userId,
                exam: flashcardSet.exam_v2,
                examUnit: flashcardSet.examUnit,
                subject: flashcardSet.subject,
                topic: flashcardSet.topic,
            });
        }, []);

        const items = useMemo(() => {
            const createFlashcardsData = (flashcards: Flashcard[]): FlashcardItem[] => {
                const starredFlashcardIds = starred || [];

                const items: FlashcardItemCard[] = flashcards.map(flashcard =>
                    createFlashcardItemCard({
                        flashcard,
                        flashcardStudyStates,
                        starredFlashcardIds,
                        toggleFlashcardStarByIds,
                    })
                );

                return canShowAds ? mixAdsWithFlashcardItems({ items, interval: 5 }) : items;
            };

            const unfilteredItems = createFlashcardsData(flashcards ?? []);

            if (!query && !activeSection && !filterStarredItems) return unfilteredItems;

            if (filterStarredItems)
                return unfilteredItems.filter(item => {
                    if (item.type === "ad") return false;
                    return (
                        item.isStarred &&
                        (item.flashcard.term?.toLowerCase().includes(query.toLowerCase().trim()) ||
                            item.flashcard.definition?.toLowerCase().includes(query.toLowerCase().trim()))
                    );
                });

            return unfilteredItems.filter(item => {
                if (item.type === "ad") return false;
                return (
                    (!activeSection || item.progress === activeSection) &&
                    (item.flashcard.term?.toLowerCase().includes(query.toLowerCase().trim()) ||
                        item.flashcard.definition?.toLowerCase().includes(query.toLowerCase().trim()))
                );
            });
        }, [
            flashcards,
            flashcardStudyStates,
            starred,
            toggleFlashcardStarByIds,
            canShowAds,
            activeSection,
            filterStarredItems,
            query,
        ]);

        const { allStarred, totalActiveFlashcards, flashcardIdsInCurrentGroup } = useMemo(() => {
            const starredFlashcardIds = starred || [];

            const flashcardIdsInCurrentGroup = items.reduce<string[]>((acc, item) => {
                if (item.type === "ad") return acc;
                return [...acc, item.flashcard.flashcardId];
            }, []);

            const allStarred = flashcardIdsInCurrentGroup.every(flashcardId =>
                starredFlashcardIds.includes(flashcardId)
            );

            return {
                allStarred,
                totalActiveFlashcards: items.filter(({ type }) => type !== "ad").length,
                flashcardIdsInCurrentGroup,
            };
        }, [items, starred]);

        const toggleActiveSectionStars = useCallback(() => {
            toggleFlashcardStarByIds(flashcardIdsInCurrentGroup, !allStarred);
        }, [allStarred, flashcardIdsInCurrentGroup, toggleFlashcardStarByIds]);

        const [showSignupPopup, setShowSignupPopup] = useState(false);

        const showEditFlashcard = useCallback((flashcard: Flashcard) => {
            setEditFlashcardPopupOpen(true);
            setFlashcardToEdit(flashcard);
        }, []);

        const shouldShowAdSlot = canShowAds && !mediaMode;

        useDOMEventListener("scroll", _ => {
            setShowSignupPopup(!userId && window.scrollY >= 3000);
        });

        return (
            <SWRConfig
                value={{
                    fallback: {
                        getCurrentUser: serverUser,
                        [unstable_serialize(["flashcard-set", flashcardSetId, userId])]: serverFlashcardSet,
                        [unstable_serialize(["flashcardSetViewer", userId, flashcardSetId])]: serverFlashcardSetViewer,
                        [unstable_serialize(["study-session", userId, flashcardSetId])]: serverStudySession,
                    },
                }}>
                {/* BLURRED SIGNUP CARD FOR UNLOGGED IN USER */}
                <LazyLoaded load={showSignupPopup}>
                    <SignUpPopup show={showSignupPopup} />
                </LazyLoaded>
                {!hideHeader && <FlashcardOverviewNavbar serverUser={serverUser} flashcardSetId={flashcardSetId} />}
                <TextToSpeechContextProvider flashcardSetId={flashcardSetId}>
                    <Flex
                        className={styles.container}
                        style={{ position: "relative", maxWidth: "173rem", margin: "0 auto" }}>
                        <FlexColumn style={{ flex: 1, margin: "0 auto", maxWidth: "124rem", height: "100%" }}>
                            <FlashcardSetHeader
                                totalFlashcards={flashcards?.length}
                                totalStarredFlashcards={starred?.length}
                                flashcardSet={flashcardSet}
                                readOnly={readOnly}
                                allStarred={allStarred}
                                totalActiveFlashcards={totalActiveFlashcards}
                                activeSection={activeSection}
                                setActiveSection={setActiveSection}
                                filterStarredItems={filterStarredItems}
                                setFilterStarredItems={setFilterStarredItems}
                                toggleActiveSectionStars={toggleActiveSectionStars}
                                showEditFlashcard={showEditFlashcard}
                                setQuery={setQuery}
                                updateFlashcardSetMetaData={updateFlashcardSetMetaData}
                            />
                            <Virtuoso
                                className={clsx(styles.flashcardsList, WALKTHROUGH_SET_LIST)}
                                useWindowScroll
                                customScrollParent={parent ?? undefined}
                                initialItemCount={Math.min(items.length, 100)}
                                data={items}
                                style={{ flex: 1, margin: "0 auto", maxWidth: "124rem", height: "100%" }}
                                context={{
                                    readOnly,
                                    showEditFlashcard,
                                    toggleFlashcardStarByIds,
                                    flashcardsMastery,
                                    setMasteryDetailsPopupIndex,
                                    starredFlashcards: starred || [],
                                }}
                                components={{
                                    Footer: mediaMode
                                        ? () => null
                                        : () => (
                                              <>
                                                  {!readOnly ? (
                                                      <Link href={`/flashcards/${flashcardSetId}/edit`}>
                                                          <CircularRectTextButton
                                                              sx={{
                                                                  backgroundColor: themeColors.primary,
                                                                  color: themeColors.primaryDark,
                                                                  borderRadius: "5rem",
                                                                  fontWeight: "600",
                                                                  fontSize: "1.8rem",
                                                                  padding: "1rem 2rem",
                                                                  margin: "3rem auto 0",
                                                              }}>
                                                              {"Add or remove terms"}
                                                          </CircularRectTextButton>
                                                      </Link>
                                                  ) : null}
                                                  {topNotes && topFlashcardSets && !mediaMode && (
                                                      <FlexColumn
                                                          style={{
                                                              gap: spacing.LG,
                                                              margin: `${spacing.LG} 0`,
                                                          }}>
                                                          <ExploreTopUserContents notes={topNotes} />
                                                          <ExploreTopUserContents flashcardSets={topFlashcardSets} />
                                                      </FlexColumn>
                                                  )}
                                                  {!mediaMode && mounted && (
                                                      <Footer ad={true} style={{ marginTop: "10vh" }} />
                                                  )}
                                              </>
                                          ),
                                }}
                                itemContent={(i, item, context) => {
                                    return isTeachersClassSet ? (
                                        <MasteryPerFlashcard index={i} item={item} context={context} />
                                    ) : (
                                        <FlashcardItemRow index={i} item={item} context={context} />
                                    );
                                }}
                            />
                            <LazyLoaded load={isTeachersClassSet && masteryDetailsPopupIndex > -1}>
                                <MasteryPerFlashcardDetailsPopup
                                    isOpen={masteryDetailsPopupIndex > -1}
                                    onClose={() => setMasteryDetailsPopupIndex(-1)}
                                    currentFlashcardIndex={
                                        masteryDetailsPopupIndex > -1 ? masteryDetailsPopupIndex : undefined
                                    }
                                    allFlashcards={flashcards}
                                    members={course?.members}
                                    flashcardsStudyAnalytics={flashcardsStudyAnalytics}
                                />
                            </LazyLoaded>
                            {shouldShowAdSlot && <MainContainerAdSlot user={user} organization={organization} />}
                        </FlexColumn>
                    </Flex>
                </TextToSpeechContextProvider>

                {/* WALKTHROUGH RELATED INJECTION */}
                {(() => {
                    if (serverFlashcardSet?.classId) {
                        if (!readOnly) {
                            return <ClassOwnSetWalkthrough />;
                        }
                        return <ClassStudentViewPointWalkthrough />;
                    }
                    return <NonClassSetWalkthrough />;
                })()}

                <LazyLoaded load={editFlashcardPopupOpen}>
                    <EditableFlashcardPopup
                        isOpen={editFlashcardPopupOpen}
                        onClose={() => setEditFlashcardPopupOpen(false)}
                        flashcard={flashcardToEdit || flashcards?.[0]}
                        dialogProps={{
                            sx: {
                                [`.${dialogClasses.container}`]: {
                                    ...(shouldShowAdSlot &&
                                        lgUp && {
                                            width: `calc(100% - ${AD_SLOT_WIDTH}px)`,
                                        }),
                                },
                            },
                            slotProps: {
                                backdrop: {
                                    style: {
                                        backdropFilter: "brightness(0.8)",
                                        WebkitBackdropFilter: "brightness(0.8)",
                                    },
                                },
                            },
                        }}
                    />
                </LazyLoaded>
            </SWRConfig>
        );
    }
);

MemoizedFlashcardSetOverviewComponent.displayName = "MemoizedFlashcardSetOverviewComponent";

// separating the components into two to avoid renders triggered by `useFlashcardSetViewer`
// to unnecessarily re-render the entire component.
const FlashcardSetOverviewPage = (props: FlashcardSetOverviewPageProps) => {
    const { flashcardSetViewerData, toggleFlashcardStarByIds } = useFlashcardSetViewer({
        flashcardSetId: props.serverFlashcardSet.flashcardSetId,
        fallbackData: props.serverFlashcardSetViewer,
    });

    return (
        <MemoizedFlashcardSetOverviewComponent
            {...props}
            starred={flashcardSetViewerData.starred}
            toggleFlashcardStarByIds={toggleFlashcardStarByIds}
        />
    );
};

export default FlashcardSetOverviewPage;
