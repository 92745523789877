import { useEffect } from "react";
import { client } from "../utils/SyncUtils";
import { useCurrentUser } from "./user/useCurrentUser";
import { view } from "@/graphql/mutations";
import { platform } from "@/platform";
import { ItemType } from "@/graphql/schema";

export const useTrackNoteView = ({ noteId, isNew }: { noteId: string; isNew: boolean }) => {
    const { userId, viewerId, mutate: mutateCurrentUser } = useCurrentUser();

    useEffect(() => {
        if (isNew || !noteId || (!userId && !viewerId)) {
            return;
        }

        client
            .mutate({
                mutation: view,
                variables: { input: { userId: userId || viewerId, ID: noteId, type: ItemType.NOTE } },
            })
            .then(() => mutateCurrentUser())
            .catch(e => platform.analytics.logging().then(({ log }) => log("ERR", e)));
    }, [noteId, userId, viewerId, mutateCurrentUser, isNew]);
};

export const useTrackFlashcardSetView = ({ flashcardSetId }: { flashcardSetId: string }) => {
    const { userId, viewerId } = useCurrentUser();

    useEffect(() => {
        if (!userId && !viewerId) {
            return;
        }

        client.mutate({
            mutation: view,
            variables: {
                input: {
                    userId: userId || viewerId,
                    ID: flashcardSetId,
                    type: ItemType.FLASHCARDSET,
                },
            },
        });
    }, [flashcardSetId, userId, viewerId]);
};
export const useTrackMediaView = ({ mediaId }: { mediaId: string }) => {
    const { userId, viewerId } = useCurrentUser();

    useEffect(() => {
        if (!userId && !viewerId) {
            return;
        }

        client.mutate({
            mutation: view,
            variables: {
                input: {
                    userId: userId || viewerId,
                    ID: mediaId,
                    type: ItemType.MEDIA,
                },
            },
        });
    }, [mediaId, userId, viewerId]);
};
