"use client";

import { useStudySession } from "@knowt/syncing/hooks/study/useStudySession";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { usePublicProfile } from "@knowt/syncing/hooks/user/useUserData";
import { ItemType, MediaType, StudySessionProgressEnum, StudySessionType } from "@knowt/syncing/graphql/schema";
import { createQueryStrings } from "@knowt/syncing/utils/genericUtils";
import clsx from "clsx";
import { Eye, Star } from "lucide-react";
import { useRouter } from "next13-progressbar";
import Image from "next/image";
import { useParams } from "next/navigation";
import React, { useRef, useState } from "react";
import CircularProgress from "@/components/CircularProgress";
import LazyLoaded from "@/components/LazyLoaded";
import RatingPopup from "@/components/Popup/RatingPopup";
import { StudyingProgressCard } from "@/features/StudyPages";
import ActionArrowRight from "@/components/Circular/styled/ActionArrowRight";
import AddRatingButton from "@/components/CircularButton/styled/AddRatingButton";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import FlashcardsCarousel from "@/components/flashcards/FlashcardsCarousel";
import InputWithSearchbarIcon from "@/components/styled/input/InputWithSearchbarIcon";
import { FlashcardSetDetails } from "./SetDetails";
import ToggleStarButton from "./styled/ToggleStarButton";
import { FlashcardSetContext } from "../types";
import { themeColors } from "@/utils/themeColors";
import { spacing } from "@/utils/spacing";
import { getProgressPillProps } from "@/utils/progressPill";
import { getNoteUrl } from "@/utils/url";
import { iconSizes } from "@/utils/iconProps";
import { borderRadius } from "@/utils/borderRadius";
import { useRatingWhenExit } from "@/hooks/useRatingPopupWhenExit";
import useDOMEventListener from "@/hooks/useDOMEventListener";
import { useCheckAuth } from "@/hooks/useCheckAuth";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";
import TileCards, { TileCardsItem } from "@/components/TileCards";
import StarRating from "@/components/StarRating";
import ContentTagBreadCrumb from "@/components/ContentTagBreadCrumb";
import styles from "../flashcardSetOverview.module.css";
import { Flex, FlexRowAlignCenter } from "@/components/Flex";
import { HorizontallyCenteredRepeatableLeaderboardAdSlot } from "@/features/Ads";
import Link from "@/components/wrappers/Link";
import UserContentOwnerCard from "@/components/UserContentOwnerCard";
import { ASSETS_URL } from "@/config/deployConstants";
import { useMedia } from "@/hooks/media/useMedia";
import Skeleton from "@mui/material/Skeleton";
import br from "@/styles/breakpoints.module.css";
import { mutate } from "swr";
import { ORDERED_FLASHCARD_STUDY_SECTIONS } from "@knowt/syncing/hooks/study/constants";
import { ReviewModeContextProvider } from "@knowt/syncing/context/FlashcardReviewModeContext";
import { AuthEvent } from "@/features/Auth";
import { ClassBreadcrumb, CompletionProgress } from "@/features/UserContentManagement";
import { useClass } from "@knowt/syncing/hooks/classes/useClass";
import { WALKTHROUGH_SET_PROGRESS_CARD } from "./Walkthroughs";

const flashcardBlackImg = `${ASSETS_URL}/images/flashcards-black.png`;
const brainImg = `${ASSETS_URL}/images/brain.png`;
const examImg = `${ASSETS_URL}/images/ExamIcon.png`;
const spacedRepetitionImg = `${ASSETS_URL}/images/Spaced_Repetition.png`;
const heartPuzzleImg = `${ASSETS_URL}/images/heart_puzzle.png`;

const StudyButton = ({
    image,
    alt,
    label,
    description,
}: {
    image: string;
    alt: string;
    label: string;
    description: string;
}) => {
    return (
        <Flex style={{ flexDirection: "row", alignItems: "center" }}>
            <Image
                src={image}
                className={styles.tileCardImage}
                width={35}
                height={35}
                alt={alt}
                style={{ marginRight: spacing.XS_2, objectFit: "contain" }}
            />
            <div>
                <span style={{ fontWeight: "600", fontSize: "1.4rem" }}>{label}</span>
                <p className="secondaryText2" style={{ width: "90%", marginTop: "0.5rem" }}>
                    {description}
                </p>
            </div>
        </Flex>
    );
};

const LinkedResourceButton = ({
    mediaId,
    noteId,
}: {
    mediaId?: string | null | undefined;
    noteId?: string | null | undefined;
}) => {
    const { media } = useMedia({ mediaId: mediaId ?? "" });

    const [isHover, setIsHover] = useState(false);

    const mediaImagesAndColors = {
        [MediaType.PDF]: {
            image: `${ASSETS_URL}/images/pdf-icon.svg`,
            color: themeColors.pdfLight,
        },
        [MediaType.XLSX]: {
            image: `${ASSETS_URL}/images/xlsx-icon.svg`,
            color: themeColors.excelLight,
        },
        [MediaType.PPTX]: {
            image: `${ASSETS_URL}/images/pptx-icon.svg`,
            color: themeColors.pptLight,
        },
        [MediaType.VIDEO]: {
            image: `${ASSETS_URL}/images/video-icon.svg`,
            color: themeColors.videoLight,
        },
    };

    const content = {
        note: {
            title: "View linked note",
            color: themeColors.docsLight,
            image: `${ASSETS_URL}/images/google-docs.png`,
            href: getNoteUrl({ noteId: noteId ?? "" }),
        },
        media: {
            title: `View linked ${media?.type?.toLowerCase() ?? "media"}`,
            color: mediaImagesAndColors[media?.type]?.color ?? themeColors.videoLight,
            image: mediaImagesAndColors[media?.type]?.image ?? `${ASSETS_URL}/images/video-icon.svg`,
            href: `/media/${mediaId}`,
        },
    }[mediaId ? "media" : "note"];

    if (mediaId && !media) {
        return (
            <FlexRowAlignCenter
                style={{
                    gap: spacing.XS,
                    backgroundColor: isHover ? themeColors.neutral3 : content.color,
                    color: isHover ? themeColors.neutralWhite : themeColors.neutralBlack,
                    transition: "all 0.2s ease-in-out",
                    padding: spacing.XS,
                    paddingLeft: "2rem",
                    borderRadius: borderRadius.card,
                    width: "16rem",
                    height: "5.2rem",
                    maxHeight: "5.2rem",
                }}>
                <Skeleton animation="wave" variant="circular" width={"2.4rem"} height={"2.4rem"} />
                <Skeleton animation="wave" height={16} width={93} />
            </FlexRowAlignCenter>
        );
    }

    return (
        <Link
            href={content.href}
            onMouseOver={() => setIsHover(true)}
            onMouseOut={() => setIsHover(false)}
            style={{
                textDecoration: "inherit",
                fontSize: "inherit",
                fontFamily: "inherit",
                fontWeight: "inherit",
            }}>
            <FlexRowAlignCenter
                style={{
                    gap: spacing.XS,
                    backgroundColor: isHover ? themeColors.neutral3 : content.color,
                    color: isHover ? themeColors.neutralWhite : themeColors.neutralBlack,
                    transition: "all 0.2s ease-in-out",
                    padding: spacing.XS,
                    paddingRight: spacing.SM,
                    borderRadius: borderRadius.card,
                    width: "max-content",
                    height: "min-content",
                }}>
                <div
                    style={{
                        padding: spacing.XS,
                    }}>
                    <div
                        style={{
                            position: "relative",
                            width: iconSizes.MD,
                            height: iconSizes.MD,
                        }}>
                        <Image fill src={content.image} alt="back" style={{ objectFit: "contain" }} />
                    </div>
                </div>
                <p className="secondaryText1n">{content.title}</p>
            </FlexRowAlignCenter>
        </Link>
    );
};

const SectionButton = ({
    section,
    numFlashcards,
    activeSection,
    setActiveSection,
    filterStarredItems,
}: {
    section?: StudySessionProgressEnum;
    numFlashcards: number;
    activeSection?: StudySessionProgressEnum;
    setActiveSection: (section?: StudySessionProgressEnum) => void;
    filterStarredItems: boolean;
}) => {
    const hasSection = !!section;

    const {
        backgroundColor = themeColors.neutralBlack,
        label,
        color = themeColors.neutralWhite,
        progressBg,
        progressValue,
    } = getProgressPillProps(section);

    const isBtnActive = activeSection === section && !filterStarredItems;

    return (
        <CircularRectTextButton
            sx={{
                borderColor: isBtnActive ? backgroundColor : themeColors.neutralWhite,
                gap: spacing.XS,
                padding: `${spacing.SM} ${hasSection ? spacing.XS_2 : spacing.SM}`,
                backgroundColor: isBtnActive ? backgroundColor : themeColors.neutralWhite,
                height: "auto",
                width: "auto",
            }}
            onClick={() => setActiveSection(section)}>
            {hasSection && (
                <CircularProgress
                    value={progressValue}
                    size={"2rem"}
                    thickness={8}
                    bgColor={isBtnActive ? progressBg : "#e4e4e4"}
                    color={isBtnActive ? color : "#000"}
                    disableAnimation
                />
            )}
            {!hasSection && <Eye size={iconSizes.SM} color={isBtnActive ? themeColors.neutralWhite : undefined} />}
            <p className="secondaryText1" style={{ color: isBtnActive ? color : undefined }}>
                {`${hasSection ? label : "View all"} (${numFlashcards})`}
            </p>
        </CircularRectTextButton>
    );
};

const FlashcardSetHeader = ({
    totalFlashcards,
    totalStarredFlashcards,
    flashcardSet,
    readOnly,
    allStarred,
    totalActiveFlashcards,
    activeSection,
    setActiveSection,
    filterStarredItems,
    setFilterStarredItems,
    toggleActiveSectionStars,
    showEditFlashcard,
    setQuery,
    updateFlashcardSetMetaData,
}: FlashcardSetContext) => {
    const router = useRouter();
    const params = useParams();
    const flashcardSetId = params?.flashcardSetId as string;

    const { smDown } = useBreakPoints();

    const { checkAuth } = useCheckAuth();
    const { userId, canShowAds, isTeacher } = useCurrentUser();

    const { studySession } = useStudySession({
        flashcardSetId: flashcardSetId,
        type: StudySessionType.LEARN,
    });

    const ratingStats = {
        rating: flashcardSet.rating,
        count: flashcardSet.ratingCount,
    };

    const { onCloseRatingPopup, userRating, ratingPopupOpen, setRatingPopupOpen } = useRatingWhenExit({
        noteId: flashcardSetId,
        readOnly,
        showAfterReturningFromStudy: true,
        ratingStats,
    });

    const { noteId, userId: ownerId, mediaId } = flashcardSet;

    const { userData: ownerData } = usePublicProfile(ownerId);

    const { course } = useClass({ classId: flashcardSet.classId });

    const title = flashcardSet.title;

    const inputRef = useRef<HTMLInputElement>(null);

    useDOMEventListener("keydown", async (event: KeyboardEvent) => {
        const isCtrlKey = navigator.platform.indexOf("Mac") > -1 ? event.metaKey : event.ctrlKey;
        if (!(event.keyCode === 114 || (isCtrlKey && event.keyCode === 70))) return;
        event.preventDefault();

        inputRef.current?.focus();
    });

    const handleLearnMode = async (studyFrom?: StudySessionProgressEnum) => {
        await mutate(["round-data", userId, flashcardSetId, StudySessionType.LEARN], null);
        router.push(`/study/flashcards/${flashcardSetId}/learn${createQueryStrings({ studyFrom })}`);
    };

    const handleTestMode = async () => {
        await mutate(["round-data", userId, flashcardSetId, StudySessionType.TEST], null);
        router.push(`/study/flashcards/${flashcardSetId}/test`);
    };

    const handleSpacedRepetitionMode = async () => {
        await mutate(["round-data", userId, flashcardSetId, StudySessionType.SPACED], null);
        router.push(`/study/flashcards/${flashcardSetId}/spaced`);
    };

    const handleMatchMode = async () => {
        await mutate(["round-data", userId, flashcardSetId, StudySessionType.MATCH], null);
        router.push(`/study/flashcards/${flashcardSetId}/match`);
    };

    const handleReviewMode = async () => {
        await mutate(["round-data", userId, flashcardSetId, StudySessionType.REVIEW], null);
        router.push(`/study/flashcards/${flashcardSetId}/review`);
    };

    const renderLeftSection = () => {
        const flashcardsTileCardsItems: TileCardsItem[] = [
            {
                key: "learn-mode",
                leftComponent: (
                    <StudyButton
                        image={brainImg}
                        alt="learn"
                        label="Learn"
                        description="A personalized and smart learning plan"
                    />
                ),
                rightComponent: <ActionArrowRight />,
                onClick: () =>
                    checkAuth(() => handleLearnMode(), {
                        event: AuthEvent.STUDY_FLASHCARDS_LEARN_MODE,
                    }),
            },
            {
                key: "practice-mode",
                leftComponent: (
                    <StudyButton
                        image={examImg}
                        alt="exam"
                        label="Practice Test"
                        description="Take a test on your terms and definitions"
                    />
                ),
                rightComponent: <ActionArrowRight />,
                onClick: () =>
                    checkAuth(() => handleTestMode(), {
                        event: AuthEvent.STUDY_FLASHCARDS_TEST_MODE,
                    }),
            },
            {
                key: "spaced-mode",
                leftComponent: (
                    <StudyButton
                        image={spacedRepetitionImg}
                        alt="spaced repetition"
                        label="Spaced Repetition"
                        description="Scientifically backed study method"
                    />
                ),
                rightComponent: <ActionArrowRight />,
                onClick: () =>
                    checkAuth(() => handleSpacedRepetitionMode(), {
                        event: AuthEvent.STUDY_FLASHCARDS_SPACED_MODE,
                    }),
            },
            {
                key: "matching-game",
                leftComponent: (
                    <StudyButton
                        image={heartPuzzleImg}
                        alt="heart puzzle"
                        label="Matching Game"
                        description="How quick can you match all your cards?"
                    />
                ),
                rightComponent: <ActionArrowRight />,
                onClick: () =>
                    checkAuth(() => handleMatchMode(), {
                        event: AuthEvent.STUDY_FLASHCARDS_MATCH_MODE,
                    }),
            },
            {
                key: "flashcards-mode",
                leftComponent: (
                    <StudyButton
                        image={flashcardBlackImg}
                        alt="flashcards"
                        label="Flashcards"
                        description="Study terms and definitions"
                    />
                ),
                rightComponent: <ActionArrowRight />,
                onClick: () =>
                    checkAuth(() => handleReviewMode(), {
                        event: AuthEvent.STUDY_FLASHCARDS_REVIEW_MODE,
                    }),
            },
        ];

        return (
            <Flex
                style={{
                    flexDirection: "column",
                    width: "100%",
                    gap: spacing.XS_2,
                }}>
                {flashcardsTileCardsItems.map((item, i) => {
                    return (
                        <TileCards
                            key={i + (item.key || "")}
                            items={[item]}
                            className={styles.tileCard}
                            style={{
                                justifySelf: "center",
                                alignSelf: "stretch",
                                justifyContent: "space-between",
                                padding: spacing.MD,
                            }}
                        />
                    );
                })}
            </Flex>
        );
    };

    const renderSetDetailsSections = () => (
        <Flex
            className={br.smDownColumn}
            style={{
                justifyContent: isTeacher ? "space-between" : "flex-start",
                margin: "2rem auto 5rem",
                gap: spacing.MD,
            }}>
            {isTeacher && flashcardSet.classId && flashcardSet.userId === userId ? (
                <CompletionProgress
                    classId={flashcardSet.classId}
                    flashcardSet={flashcardSet}
                    onSeeMoreClick={() =>
                        router.push(`/class/${flashcardSet.classId}/dashboard?tab=progress&set=${flashcardSetId}`)
                    }
                    onProgressClick={progress =>
                        router.push(
                            `/class/${flashcardSet.classId}/dashboard?tab=progress&set=${flashcardSetId}&progress=${progress}`
                        )
                    }
                    containerStyle={{ width: "60%", padding: "2.4rem" }}
                    className={clsx(br.smDownWidth100, WALKTHROUGH_SET_PROGRESS_CARD)}
                />
            ) : (
                <UserContentOwnerCard
                    className={br.smDownWidth100}
                    owner={ownerData}
                    variant={isTeacher ? "vertical" : "horizontal"}
                    style={{
                        padding: "1.5rem",
                        ...(isTeacher ? { width: "22rem", height: "25.6rem" } : { width: "40%" }),
                    }}
                />
            )}
            <FlashcardSetDetails
                flashcardSet={flashcardSet}
                updateFlashcardSetMetaData={updateFlashcardSetMetaData}
                readOnly={readOnly}
            />
        </Flex>
    );

    return (
        <div>
            <Flex style={{ marginBottom: spacing.MD, flexDirection: "row", alignItems: "center", gap: spacing.LG }}>
                {flashcardSet.classId ? (
                    <ClassBreadcrumb course={course} />
                ) : (
                    <ContentTagBreadCrumb flashcardSet={flashcardSet} />
                )}
            </Flex>
            {/* TOP */}
            <Flex
                className={styles.titleContainer}
                style={{ justifyContent: "space-between", width: "100%", marginBottom: "3.5rem", gap: spacing.MD }}>
                <Flex style={{ flexDirection: "column", justifyContent: "flex-start" }}>
                    <div style={{ marginBottom: "2.8rem" }}>
                        <h1
                            style={{
                                fontSize: "3rem",
                                fontWeight: "bold",
                                textOverflow: "ellipsis",
                                wordBreak: "break-all",
                                overflow: "hidden",
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: "vertical",
                            }}>
                            {title}
                        </h1>
                    </div>
                    <Flex className={styles.flashcardStats} style={{ width: "100%" }}>
                        <FlexRowAlignCenter style={{ marginRight: spacing.LG }}>
                            <Image
                                src={`${ASSETS_URL}/images/views-icon.svg`}
                                alt={"studied by"}
                                width={25}
                                height={25}
                                style={{ objectFit: "contain" }}
                            />
                            Studied by {flashcardSet.views ?? 0} {(flashcardSet.views ?? 0) === 1 ? "person" : "people"}
                        </FlexRowAlignCenter>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                maxWidth: "48rem",
                                width: "100%",
                                gap: "2.5rem",
                            }}>
                            <FlexRowAlignCenter style={{ gap: "1rem" }}>
                                <span style={{ fontWeight: "600" }}>
                                    {(ratingStats?.rating || flashcardSet?.rating || 0).toFixed(1)}
                                </span>
                                <StarRating
                                    readOnly
                                    sx={{ fontSize: "2rem" }}
                                    value={ratingStats?.rating || flashcardSet?.rating || 0}
                                />
                                <span style={{ fontWeight: "600", color: themeColors.neutral3 }}>
                                    ({ratingStats?.count || flashcardSet?.rating || 0})
                                </span>
                            </FlexRowAlignCenter>
                            {readOnly && (
                                <AddRatingButton setRatingPopupOpen={setRatingPopupOpen} isRated={!!userRating} />
                            )}
                        </div>
                    </Flex>
                </Flex>
                <Flex className={styles.headerButtons}>
                    {(noteId || mediaId) && <LinkedResourceButton noteId={noteId} mediaId={mediaId} />}
                </Flex>
            </Flex>
            {isTeacher && flashcardSet.classId && renderSetDetailsSections()}
            <div className={clsx(styles.topSection)} style={{ marginBottom: "5rem" }}>
                <div className={styles.topSectionLeft}>{renderLeftSection()}</div>
                <div className={clsx(styles.topSectionRight)}>
                    <ReviewModeContextProvider flashcardSet={flashcardSet} flashcardSetId={flashcardSetId}>
                        <FlashcardsCarousel showEditFlashcard={showEditFlashcard} />
                    </ReviewModeContextProvider>
                </div>
            </div>
            {canShowAds && (
                <HorizontallyCenteredRepeatableLeaderboardAdSlot
                    className={br.mdDownDisplayNone}
                    containerStyle={{
                        display: "flex",
                        marginBottom: "3rem",
                    }}
                />
            )}
            {/* MIDDLE */}
            {(!isTeacher || !flashcardSet.classId) && renderSetDetailsSections()}
            {!isTeacher && (
                <StudyingProgressCard
                    studySession={studySession}
                    total={totalFlashcards}
                    style={{
                        borderRadius: borderRadius.card,
                        width: "100%",
                        marginBottom: spacing.LG,
                    }}
                    onStudy={studyFrom => handleLearnMode(studyFrom)}
                />
            )}
            <Flex
                style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: spacing.LG,
                }}>
                <div className="heading5">{totalFlashcards} Terms</div>
                <InputWithSearchbarIcon
                    className="bodyBold2"
                    ref={inputRef}
                    onChange={e => setQuery(e.target.value.trim())}
                    placeholder={"Search terms/definitions"}
                    sx={{
                        width: !smDown ? "38rem" : "100%",
                        transition: "width 0.3s",
                        color: "var(--color-neutral-black)",
                        border: "solid",
                        borderWidth: 1,
                        borderColor: themeColors.neutralBlack,
                        "&:focus": { width: smDown ? "100%" : "50rem" },
                    }}
                />
            </Flex>
            <Flex
                className={styles.studySection}
                style={{
                    alignItems: "center",
                    width: "100%",
                }}>
                <div
                    className={clsx(styles.studySectionButtons, "scrollbar-thin")}
                    style={{ gap: spacing.MD, overflowX: "auto", paddingRight: "1.5rem" }}>
                    <SectionButton
                        setActiveSection={val => {
                            setActiveSection(val);
                            setFilterStarredItems(false);
                        }}
                        activeSection={activeSection}
                        numFlashcards={totalFlashcards}
                        filterStarredItems={filterStarredItems}
                    />
                    {ORDERED_FLASHCARD_STUDY_SECTIONS.map(section => {
                        let numFlashcards = studySession?.progress?.[section] ?? 0;
                        if (!studySession?.progress && section === StudySessionProgressEnum.NEW) {
                            numFlashcards = totalFlashcards;
                        } else if (numFlashcards === 0) {
                            return null;
                        }

                        return (
                            <SectionButton
                                key={section}
                                section={section}
                                activeSection={activeSection}
                                setActiveSection={val => {
                                    setActiveSection(val);
                                    setFilterStarredItems(false);
                                }}
                                numFlashcards={numFlashcards}
                                filterStarredItems={filterStarredItems}
                            />
                        );
                    })}
                    {!!totalStarredFlashcards && (
                        <CircularRectTextButton
                            sx={{
                                borderColor: filterStarredItems ? themeColors.neutralBlack : themeColors.neutralWhite,
                                gap: spacing.XS,
                                padding: spacing.SM,
                                backgroundColor: filterStarredItems
                                    ? themeColors.neutralBlack
                                    : themeColors.neutralWhite,
                                height: "auto",
                                width: "auto",
                            }}
                            onClick={() => setFilterStarredItems(val => !val)}>
                            <Star
                                size={iconSizes.SM}
                                fill={filterStarredItems ? themeColors.primary3 : undefined}
                                color={filterStarredItems ? themeColors.primary3 : undefined}
                            />
                            <p
                                className="secondaryText1"
                                style={{
                                    color: filterStarredItems ? themeColors.neutralWhite : undefined,
                                }}>{`Starred (${totalStarredFlashcards})`}</p>
                        </CircularRectTextButton>
                    )}
                </div>
                <ToggleStarButton
                    allStarred={allStarred}
                    numOfFlashcards={totalActiveFlashcards}
                    onClick={() => checkAuth(() => toggleActiveSectionStars(), { event: AuthEvent.STAR_FLASHCARD })}
                />
            </Flex>
            <LazyLoaded load={ratingPopupOpen}>
                <RatingPopup
                    documentId={flashcardSetId}
                    documentType={ItemType.FLASHCARDSET}
                    documentOwnerId={ownerId}
                    userRating={userRating}
                    isOpen={ratingPopupOpen}
                    onClose={onCloseRatingPopup}
                />
            </LazyLoaded>
        </div>
    );
};

export default FlashcardSetHeader;
