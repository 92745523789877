export const COVER_COLORS = ["#E0736C", "#E0B96C", "#ADE06C", "#6CE09A", "#6CD2E0", "#6C7EE0", "#B46CE0", "#E06CAB"];

export const SECTIONS_COLORS = [
    "#FFE6DB",
    "#FFFEDC",
    "#ECFFD9",
    "#DAFFED",
    "#DBF9FF",
    "#DDE3FF",
    "#EBDCFF",
    "#FAD9FF",
    "#FFD7EA",
    "#FFDDDD",
];

export const SECTIONS_PILLS_TEXT_COLORS = {
    "#FFE6DB": "#5F301A",
    "#FFFEDC": "#57541A",
    "#ECFFD9": "#39531F",
    "#DAFFED": "#1A6844",
    "#DBF9FF": "#165A69",
    "#DDE3FF": "#1B2A7D",
    "#EBDCFF": "#471E7A",
    "#FAD9FF": "#55115F",
    "#FFD7EA": "#7A1A47",
    "#FFDDDD": "#691111",
};
