import { Flex, FlexColumnAlignJustifyCenter } from "@/components/Flex";
import { PieChart } from "@mui/x-charts/PieChart";
import { StudySessionProgressEnum } from "@knowt/syncing/graphql/schema";
import { getProgressBg } from "@/utils/progressPill";
import ConditionalTooltip from "@/components/wrappers/ConditionalTooltip";
import { getProgressLabel } from "@knowt/syncing/hooks/study/statsUtils";
import { themeColors } from "@/utils/themeColors";
import Skeleton from "@mui/material/Skeleton";

const ProgressPieChart = ({
    completionStats,
    radius = 100,
    thickness = radius * 0.4,
}: {
    completionStats: Record<StudySessionProgressEnum, number> | null | undefined;
    radius?: number;
    thickness?: number;
}) => {
    const isLoading = completionStats === undefined;
    const total = (completionStats && Object.values(completionStats).reduce((total, count) => total + count, 0)) || 0;
    const isEmptyState = !completionStats || total === 0;

    const pieChartData = isEmptyState
        ? [
              {
                  value: 100,
                  color: themeColors.neutral1,
              },
          ]
        : (Object.entries(completionStats) as [StudySessionProgressEnum, number][]).map(([label, value]) => ({
              value,
              color: getProgressBg(label as StudySessionProgressEnum),
              label: getProgressLabel(label),
          }));

    const masteredPercentage =
        (!isEmptyState && Math.round((completionStats[StudySessionProgressEnum.MASTERED] / total) * 100)) || 0;

    if (isLoading) {
        return (
            <FlexColumnAlignJustifyCenter style={{ margin: "0.5rem 0", position: "relative", paddingLeft: "0.5rem" }}>
                <Skeleton variant="circular" width={radius * 2} height={radius * 2} />
                <Flex
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "calc(50% + 0.25rem)",
                        transform: "translate(-50%, -50%)",
                        borderRadius: "50%",
                        backgroundColor: themeColors.neutralWhite,
                        width: radius * 0.6 * 2,
                        height: radius * 0.6 * 2,
                    }}
                />
            </FlexColumnAlignJustifyCenter>
        );
    }

    return (
        <FlexColumnAlignJustifyCenter style={{ position: "relative" }}>
            <PieChart
                series={[
                    {
                        data: pieChartData,
                        innerRadius: radius - thickness,
                        outerRadius: radius,
                        paddingAngle: 0,
                        cornerRadius: 0,
                        startAngle: 0,
                        endAngle: 360,
                        ...(!isEmptyState && {
                            highlightScope: { faded: "global", highlighted: "item" },
                            highlighted: {
                                innerRadius: radius - thickness - radius * 0.05,
                                additionalRadius: radius * 0.05,
                            },
                            faded: { color: "gray" },
                        }),
                    },
                ]}
                tooltip={{
                    trigger: isEmptyState ? "none" : "item",
                }}
                skipAnimation
                slotProps={{
                    legend: { hidden: true },
                }}
                width={radius * 2 + 10} // 10 is for a little padding
                height={radius * 2 + 10}
                margin={{ right: 0, left: 0 }}
            />
            <ConditionalTooltip tooltip={`${masteredPercentage}% mastered`} placement="top">
                <FlexColumnAlignJustifyCenter
                    style={{
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)",
                    }}>
                    <div className={"bodyBold1"}>{masteredPercentage}%</div>
                    {radius > 50 && <div className={"secondaryText2"}>mastered</div>}
                </FlexColumnAlignJustifyCenter>
            </ConditionalTooltip>
        </FlexColumnAlignJustifyCenter>
    );
};

export default ProgressPieChart;
