import { Flashcard, FlashcardSide } from "@knowt/syncing/graphql/schema";
import { memo } from "react";
import { FlashcardControls } from "./FlashcardControls";
import AddRemoveImageButton from "@/components/AddRemoveImageButton";
import { Flex, FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import { FlashcardItemCard } from "@/features/FlashcardSetOverview";
import { ProgressPill } from "@/features/StudyPages";
import { useTextToSpeechContextSelector } from "@/contexts/TextToSpeechContext";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import HtmlText from "components/HtmlText";
import br from "@/styles/breakpoints.module.css";
import clsx from "clsx";

type PreviewFlashcardCardProps = Omit<FlashcardItemCard, "section"> & {
    showEditFlashcard: (flashcard: Flashcard) => void;
    readOnly: boolean;
    index: number;
};

const Divider = () => {
    return (
        <>
            <div className={br.smDownDisplayNone} style={{ backgroundColor: "#E8E8E8", width: 2, height: "80%" }} />
            <div className={br.smUpDisplayNone} style={{ backgroundColor: "#E8E8E8", width: "100%", height: 2 }} />
        </>
    );
};

const PreviewFlashcardCard = memo(
    ({
        readOnly,
        flashcard,
        isStarred,
        progress,
        toggleFlashcardStarByIds,
        showEditFlashcard,
        index,
    }: PreviewFlashcardCardProps) => {
        //for media page
        const mediaMode = getMediaMode();

        function getMediaMode() {
            if (typeof window !== "undefined") {
                const path = window.location.pathname;
                return path.includes("media");
            } else {
                return false;
            }
        }

        const { term, definition, image, secondaryImage } = flashcard;

        const handlePlayAudio = useTextToSpeechContextSelector(state => state.handlePlayAudio);

        const renderButtons = () => (
            <FlexColumn
                className={clsx(br.smDownRow, br.smDownJustifySpaceBetween)}
                style={{ alignItems: "center", width: "100%", gap: spacing.SM }}>
                <ProgressPill progress={progress} />
                <FlashcardControls
                    readOnly={readOnly}
                    flashcard={flashcard}
                    isStarred={isStarred}
                    toggleStarFlashcard={() => toggleFlashcardStarByIds([flashcard.flashcardId])}
                    showEditFlashcard={showEditFlashcard}
                    audioSide={FlashcardSide.BOTH}
                />
            </FlexColumn>
        );

        return (
            <Flex
                className={br.smDownColumn}
                style={{
                    padding: "4rem 2.4rem",
                    borderRadius: "2rem",
                    backgroundColor: mediaMode ? themeColors.background : themeColors.card,
                    fontSize: "2rem",
                    width: "100%",
                    gap: "2rem",
                }}>
                <span className="heading5">{index}</span>

                {/* TERM IMAGE */}
                <FlexRowAlignCenter className={br.smDownDisplayNone} style={{ gap: "inherit", width: "fit-content" }}>
                    <AddRemoveImageButton
                        editable={false}
                        alt={term || "term image"}
                        image={secondaryImage}
                        size="9rem"
                        style={{ visibility: secondaryImage ? "visible" : "hidden" }}
                    />
                </FlexRowAlignCenter>

                <div className={br.smUpDisplayNone}>{renderButtons()}</div>
                {/* TERM AND DEFINITION TEXT */}
                <FlexRowAlignCenter
                    className={clsx(br.smDownColumn, br.smDownAlignStart)}
                    style={{
                        width: "100%",
                        gap: spacing.MD,
                        justifyContent: "space-between",
                        fontSize: "1.8rem",
                        alignItems: "center",
                    }}>
                    <Flex style={{ gap: spacing.SM, width: "35%" }}>
                        {secondaryImage && (
                            <div className={br.smUpDisplayNone}>
                                <AddRemoveImageButton
                                    editable={false}
                                    alt={term || "term image"}
                                    image={secondaryImage}
                                    size="9rem"
                                />
                            </div>
                        )}
                        <HtmlText
                            onClick={async () => await handlePlayAudio(FlashcardSide.TERM, { flashcard })}
                            text={term}
                            style={{
                                width: "100%",
                                fontFamily: "var(--knowt-font-name)",
                                background: "transparent",
                            }}
                        />
                    </Flex>

                    <Divider />

                    <Flex style={{ gap: spacing.SM, width: "65%" }}>
                        {secondaryImage && (
                            <div className={br.smUpDisplayNone}>
                                <AddRemoveImageButton
                                    editable={false}
                                    alt={term ?? "term image"}
                                    image={image}
                                    size="9rem"
                                    style={{ visibility: image ? "visible" : "hidden" }}
                                />
                            </div>
                        )}
                        <HtmlText
                            onClick={async () => await handlePlayAudio(FlashcardSide.DEFINITION, { flashcard })}
                            text={definition}
                            style={{
                                width: "100%",
                                fontFamily: "var(--knowt-font-name)",
                                background: "transparent",
                            }}
                        />
                    </Flex>
                </FlexRowAlignCenter>

                {/* DEFINITION IMAGE AND STAR */}
                <FlexRowAlignCenter
                    className={br.smDownDisplayNone}
                    style={{ marginLeft: "auto", gap: "inherit", display: "flex" }}>
                    <Flex style={{ flexDirection: "column", height: "100%" }}>
                        <FlexRowAlignCenter style={{ display: "flex", flex: 1, alignItems: "center" }}>
                            <AddRemoveImageButton
                                editable={false}
                                alt={definition ?? "definition image"}
                                image={image}
                                size="9rem"
                                style={{ visibility: image ? "visible" : "hidden" }}
                            />
                        </FlexRowAlignCenter>
                    </Flex>
                    {renderButtons()}
                </FlexRowAlignCenter>
            </Flex>
        );
    }
);

PreviewFlashcardCard.displayName = "PreviewFlashcardCard";
export default PreviewFlashcardCard;
