import { Class } from "@knowt/syncing/graphql/schema";
import Breadcrumb from "@/components/Breadcrumb";

const ClassBreadcrumb = ({ course }: { course: Class | null | undefined }) => {
    return (
        <Breadcrumb
            titleSections={
                course
                    ? [
                          {
                              title: course.name,
                              image: `/images/class-icon.svg`,
                              alt: course.name,
                              href: `/class/${course.classId}/dashboard`,
                          },
                      ]
                    : []
            }
        />
    );
};

export default ClassBreadcrumb;
